
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './core/Home';
import Signup from './user/Signup';
import PrivateRoutes from './auth/helper/PrivateRoutes';
import UserDashboard from './user/UserDashboard';
import Signin from './user/Signin';
import ManagerDashboard from './user/ManagerDashboard';
import OpDashboard from './user/OperationsDashboard';
import CreateAttendance from './user/CreateAttendance';
import NotFound from './user/NotFound';
import AdminDashboard from './user/AdminDashboard'

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/signin" element={<Signin/>} />
        <Route path="/not-found" element={<NotFound/>} />

        <Route
  path="/user/*"
  element={
    <PrivateRoutes>
      {/* Specify the nested routes within PrivateRoutes */}
      <Route path="/dashboard" element={<UserDashboard />} />
      <Route path="/dashboard-gm" element={< ManagerDashboard/>} />
      <Route path="/update" element={< ManagerDashboard/>} />
      <Route path="/dashboard-deptmanager" element={<ManagerDashboard />} requiredRole="deptManager" />
      <Route path="/dashboard-operation" element={<OpDashboard />} requiredRole="operations" />
      <Route path="/create-attendance/:workId" element={<CreateAttendance />} />
      <Route path="/dashboard-admin" element={<AdminDashboard />} requiredRole="admin" />
    </PrivateRoutes>

  }
/>
      
      </Routes>
    </BrowserRouter>
  );
};

export default App;
