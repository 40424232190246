import React, { useEffect, useState } from "react";
import { getWork, updateWork } from "../core/helper/coreapicalls";
import EditWorkPopup from "./EditWork";
import Pagination from "../core/Pagination";

const ITEMS_PER_PAGE = 100;

const AllWorks = ({ currentPage, setCurrentPage }) => {
  const [worksList, setWorksList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [sortAsc, setSortAsc] = useState(false);
  const [editedWork, setEditedWork] = useState(null);
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const loadingMessage = () => {
    return (
      loading && (
        <div className="overlay">
          <div className="d-flex justify-content-center align-items-center vh-100">
            <div className="spinner-border text-danger" role="status"></div>
          </div>
        </div>
      )
    );
  };
  const handleEditClick = (work) => {
    setEditedWork(work);
    setIsEditPopupOpen(true);
  };

  const handleSaveChanges = async (editedData) => {
    try {
      const updatedWork = await updateWork(editedData.work_id, editedData);
      // console.log("work data to be tested:" , updateWork)
      alert("Work updated successfully");
      if (updatedWork && updatedWork.work_id) {
        setWorksList((prevWorksList) =>
          prevWorksList.map((work) =>
            work.work_id === updatedWork.work_id ? updatedWork : work
          )
        );
        setIsEditPopupOpen(false);
      } else {
        alert("Work update failed");
      }
    } catch (error) {
      alert("Error updating work");
      console.error("Error updating work:", error);
    }
  };

  const handleCancelEdit = () => {
    setEditedWork(null);
    setIsEditPopupOpen(false);
  };

  const handleSearch = async () => {
    try {
      // Reset currentPage to 1 when a new search is initiated
      setCurrentPage(1);
      const response = await getWork(1, searchQuery); // Always start with page 1 for a new search
      // console.log("search result", response)
      // Normalize the response structure to match the format of the normal worklist
      const normalizedResults = Array.isArray(response)
        ? response
        : response.results;
      // console.log("results:", normalizedResults)
      setWorksList(normalizedResults);
      setTotalCount(response.count);
      //   setNextPage(response.next);
      //   setPrevPage(response.previous);
    } catch (error) {
      alert('Error searching works!!')
      console.error("Error searching works:", error);
    }
  };

  useEffect(() => {
    const fetchWorks = async () => {
      try {
        setLoading(true);
        // console.log("here is the data of current page and search query", currentPage, searchQuery)
        const response = await getWork(currentPage, searchQuery);
        const updatedWorksList = Array.isArray(response.results)
          ? response.results
          : [];

        setWorksList(updatedWorksList);
        setTotalCount(response.count);
        setLoading(false);
        // setNextPage(response.next);
        // setPrevPage(response.previous);
      } catch (error) {
        
        console.error("Error fetching works:", error);
        setLoading(false);
      }
    };

    fetchWorks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const truncationLength = 20;

  const handleReadMore = (workId) => {
    setExpandedDescriptions((prevDescriptions) => ({
      ...prevDescriptions,
      [workId]: true,
    }));
  };

  const toggleSortOrder = () => {
    setSortAsc(!sortAsc);
  };

  const handleNextPage = async () => {
    try {
      const nextPageWorks = await getWork(currentPage + 1);

      // Ensure that nextPageWorks.results is an array
      const updatedWorksList = Array.isArray(nextPageWorks.results)
        ? nextPageWorks.results
        : [];

      setWorksList(updatedWorksList);
      setTotalCount(nextPageWorks.count);
      //   setNextPage(nextPageWorks.next);
      //   setPrevPage(nextPageWorks.previous);
      setCurrentPage((prevPage) => prevPage + 1);
    } catch (error) {
      console.error("Error fetching next page works:", error);
    }
  };

  const handlePrevPage = async () => {
    try {
      const prevPageWorks = await getWork(currentPage - 1);

      // Ensure that prevPageWorks.results is an array
      const updatedWorksList = Array.isArray(prevPageWorks.results)
        ? prevPageWorks.results
        : [];

      setWorksList(updatedWorksList);
      setTotalCount(prevPageWorks.count);
      //   setNextPage(prevPageWorks.next);
      //   setPrevPage(prevPageWorks.previous);
      setCurrentPage((prevPage) => prevPage - 1);
    } catch (error) {
      console.error("Error fetching previous page works:", error);
    }
  };

  const sortedWorksList = worksList.sort((a, b) => {
    const dateA = new Date(a.updated_at);
    const dateB = new Date(b.updated_at);
    return sortAsc ? dateA - dateB : dateB - dateA;
  });

  const paginatedWorksList = sortedWorksList.slice(0, ITEMS_PER_PAGE);

  return (
    <div
      className="table-container "
      style={{
        width: "100%",
        maxHeight: "800px",
        overflowY: "auto",
        overflowX: "auto",
        margin: "0",
        padding: "0",
        
      }}
    >
        {loading && loadingMessage()}
      <div className="search-container row ">
        <div className="col   " style={{ maxWidth: 306 }}>
          <input
            type="text"
            className="form-control  mb-3"
            placeholder="Search works..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <div className="col p-0" style={{ maxWidth: 77 }}>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleSearch}
          >
            Search
          </button>
        </div>
        <div className="col-md-4">
          <button
            type="button"
            className="btn btn-secondary mb-3"
            onClick={toggleSortOrder}
          >
            {sortAsc ? "Sort Descending" : "Sort Ascending"}
          </button>
        </div>
      </div>

      <table className="table table-striped">
        <thead>
          <tr style={{ maxWidth: 20 }}>
            <th scope="col">Sl.No</th>
            <th scope="col">Vessel</th>
            <th scope="col">IMO</th>
            <th scope="col">Job No</th>
            <th style={{ minWidth: 100, maxWidth: 300 }} scope="col">
              Job Description
            </th>
            <th style={{ minWidth: 80 }} scope="col">
              PIC
            </th>
            <th style={{ minWidth: 80 }} scope="col">
              Location
            </th>
            <th style={{ minWidth: 80 }} scope="col">
              Date
            </th>
            <th style={{ minWidth: 80 }} scope="col">
              Type
            </th>
            <th style={{ maxWidth: 80 }} scope="col">
              Created At
            </th>
            {/* <th style={{ minWidth: 80 }} scope="col">
              Updated At
            </th> */}
            <th style={{ minWidth: 80 }} scope="col">
              Status
            </th>
            <th style={{ minWidth: 80 }} scope="col">
              Edit
            </th>
          </tr>
        </thead>
        <tbody>
          {paginatedWorksList.map((work, index) => (
            <tr key={work.work_id} style={{ fontSize: 12 }}>
              <th scope="row">{index + 1}</th>
              <td>{work.work_vessel}</td>
              <td>{work.work_vessel_imo ? work.work_vessel_imo : "N/A"}</td>
              <td>{work.work_job_number}</td>
              <td style={{ minWidth: 100, maxWidth: 400 }}>
                {work.work_job_description.length > truncationLength ? (
                  <>
                    {expandedDescriptions[work.work_id]
                      ? work.work_job_description
                      : work.work_job_description.slice(0, truncationLength) +
                        " "}
                    {!expandedDescriptions[work.work_id] && (
                      <button
                        type="button"
                        className="btn text-primary p-0"
                        onClick={() => handleReadMore(work.work_id)}
                      >
                        ...
                      </button>
                    )}
                  </>
                ) : (
                  work.work_job_description
                )}
              </td>
              <td style={{ maxWidth: 100 }}>{work.work_job_pic_name}</td>
              <td style={{ maxWidth: 100 }}>{work.work_location}</td>
              <td style={{ maxWidth: 100 }}>{work.work_date}</td>
              <td style={{ maxWidth: 100 }}>{work.work_type}</td>
              <td style={{ maxWidth: 100 }}>{work.created_at}</td>
              {/* <td>{work.updated_at}</td> */}
              <td style={{ color: work.work_active ? "green" : "red" }}>
                {work.work_active ? "Active" : "Inactive"}
              </td>
              <td>
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={() => handleEditClick(work)}
                >
                  <i className="fa fa-edit"></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Use the Pagination component */}
      {totalCount > ITEMS_PER_PAGE && (
        <Pagination
          currentPage={currentPage}
          totalPages={Math.ceil(totalCount / ITEMS_PER_PAGE)}
          onNextPage={handleNextPage}
          onPrevPage={handlePrevPage}
        />
      )}

      {isEditPopupOpen && (
        <EditWorkPopup
          work={editedWork}
          onSave={handleSaveChanges}
          onClose={handleCancelEdit}
        />
      )}
    </div>
  );
};

export default AllWorks;
