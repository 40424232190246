



import React from 'react';
import { Routes, Navigate } from 'react-router-dom';
import { isAuthenticated } from './index';
// import NotFound from '../../user/NotFound';


const PrivateRoutes = ({ children }) => {
  const userData = isAuthenticated() ? isAuthenticated() : null;

  if (!userData) {
    // Redirect to the signin page if the user is not authenticated
    return <Navigate to="/signin" />;
  }

  const isDeptManager = userData.emp_is_deptmanager;
  const isOperations = userData.emp_is_operation;
  const isAdmin = userData.emp_is_admin;

  return (
    <Routes>
      {React.Children.map(children, (child) => {
        // console.log("worked once")
        // Check if the user has the required role for the specific route
        if (child.props.requiredRole) {
          if (child.props.requiredRole === 'deptManager' && isDeptManager) {
            return child;
          }
          if (child.props.requiredRole === 'operations' && isOperations) {
            return child;
          } 
          if (child.props.requiredRole === 'admin' && isAdmin) {
            return child;
          } 

          // Add more role checks if needed for other routes
        } else {
          // If no specific role is required, render the component
          return child;
        }
      })}
    </Routes>
  );
};

export default PrivateRoutes;



