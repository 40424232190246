import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { signout, isAuthenticated, userRole } from '../auth/helper';



const currentTab = (path, currentPath) => {
  return {
    color: currentPath === path ? '#2ecc72' : '#FFFFFF',
  };
};

const Menu = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [userRoleState, setUserRoleState] = useState(null);


  useEffect(() => {
    const role = userRole();

    // console.log( " Here is the role ", role)
    setUserRoleState(Array.isArray(role) ? role : [role]);
  }, []);



  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container-fluid">
      <Link to='/' className='navbar-brand' style={currentTab('/', location.pathname)}>
          <img src="/Logo_Maritech Logo _White.png" alt="Logo" style={{ height: '46px', width: 'auto' }} />
        </Link>
{/*         
        <Link to='/' className='navbar-brand' style={currentTab('/', location.pathname)}>
          HOME
        </Link> */}

        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            {/* <li className='nav-item'>
                  <Link to='/cart' className='nav-link' style={currentTab('/cart', location.pathname)}>
                    Cart
                  </Link>
                </li> */}
            {isAuthenticated() && userRoleState && (userRoleState.includes('emp_is_jrsvengg') || userRoleState.includes('emp_is_srsvengg') || userRoleState.includes('emp_is_svengg')) && (
              <li className='nav-item'>
                <Link to='/user/dashboard' className='nav-link' style={currentTab('/user/dashboard', location.pathname)}>
                  Dashboard
                </Link>
              </li>
            )}


            {isAuthenticated() && userRoleState && userRoleState.includes('emp_is_operation') && (
              <li className='nav-item'>

                <Link to='/user/dashboard-operation' className='nav-link' style={currentTab('/user/dashboard-operation', location.pathname)}>
                  Dashboard
                </Link>
              </li>
            )}

            {isAuthenticated() && userRoleState && userRoleState.includes('emp_is_deptmanager') && (
              <li className='nav-item'>

                <Link to='/user/dashboard-deptmanager' className='nav-link' style={currentTab('/user/dashboard-deptmanager', location.pathname)}>
                  Dashboard
                </Link>
              </li>
            )}

            {!isAuthenticated() && (
              <>
                <li className='nav-item'>
                  <Link to='/signup' className='nav-link' style={currentTab('/signup', location.pathname)}>
                    Signup
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link to='/signin' className='nav-link' style={currentTab('/signin', location.pathname)}>
                    Signin
                  </Link>
                </li>
              </>
            )}
            {isAuthenticated() && (
              <li className='nav-item '>
                
                <span onClick={() => {
    signout(() => {
        navigate("/");
    });
}} className='nav-link text-warning'>
    Signout {'\u00A0'}
    <div className="fa fa-sign-out" aria-hidden="true" style={{ color: "orange", marginLeft: '5px' }}></div>
</span>

               
              </li>
               
            )}
            
          </ul>
        </div>
      </div>
    </nav>
  );


};

export default Menu;