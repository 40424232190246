import React, { useState } from 'react';

const SearchUser = ({ onSearch,
  SearchFor,

}) => {
  const [searchQuery, setSearchQuery] = useState('');

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearch = () => {
    onSearch(searchQuery);
  };

  return (

    <div className="mb-3 table-container  " style={{ maxWidth: '300px', overflowX: 'hidden', display: 'flex' }}>
      <div style={{ marginRight: '5px' , marginLeft: '5px'}}>      
        <input
        type="text"
        className="form-control "
        placeholder={SearchFor}
        value={searchQuery}
        onChange={handleInputChange}
         // Add spacing between input and button
      />
      </div>
      <button className="btn btn-primary " onClick={handleSearch} ><i className="fa-solid fa-search"></i></button>
    
      
  
    </div>

  );
};

export default SearchUser;
