import React, { useEffect, useState } from 'react';
import { updateTimesheet } from '../core/helper/coreapicalls';

const UpdateTimesheetForm = ({ timesheet, onUpdate, onClosePopup }) => {
    const [formData, setFormData] = useState({
        timesheet_time_start: '',
        timesheet_time_signin: '',
        timesheet_time_signout: '',
        timesheet_time_return: '',
        timesheet_expected_amount: 0,
        timesheet_is_holiday: false,
    });

    const [validationErrors, setValidationErrors] = useState([]);

    useEffect(() => {
        setFormData({
            timesheet_time_start: timesheet.timesheet_time_start || '',
            timesheet_time_signin: timesheet.timesheet_time_signin || '',
            timesheet_time_signout: timesheet.timesheet_time_signout || '',
            timesheet_time_return: timesheet.timesheet_time_return || '',
            timesheet_expected_amount: timesheet.timesheet_expected_amount || 0,
            timesheet_is_holiday: timesheet.timesheet_is_holiday || false,
        });
    }, [timesheet]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        
        const updatedValue = type === 'checkbox' ? checked : value;
        const formattedTime = value ? `${value}:00` : null;

        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'time' ? formattedTime : updatedValue,
        }));

        validateTimes({
            ...formData,
            [name]: type === 'time' ? formattedTime : updatedValue,
        });
    };

    const validateTimes = (data) => {
        const errors = [];

        if (data.timesheet_time_signin && data.timesheet_time_start) {
            const startTime = new Date(`1970-01-01T${data.timesheet_time_start}`);
            const signinTime = new Date(`1970-01-01T${data.timesheet_time_signin}`);

            if (signinTime < startTime) {
                errors.push('Sign In Time cannot be before Start Time.');
            }
        }

        if (data.timesheet_time_signout && data.timesheet_time_signin) {
            const signinTime = new Date(`1970-01-01T${data.timesheet_time_signin}`);
            const signoutTime = new Date(`1970-01-01T${data.timesheet_time_signout}`);
            const startTime = new Date(`1970-01-01T${data.timesheet_time_start}`);

            if (signoutTime < signinTime || signoutTime < startTime) {
                errors.push('Please check Sign out time');
            }
        }

        if (data.timesheet_time_return && data.timesheet_time_signout) {
            const signinTime = new Date(`1970-01-01T${data.timesheet_time_signin}`);
            const startTime = new Date(`1970-01-01T${data.timesheet_time_start}`);
            const signoutTime = new Date(`1970-01-01T${data.timesheet_time_signout}`);
            const returnTime = new Date(`1970-01-01T${data.timesheet_time_return}`);

            if (returnTime < signoutTime || returnTime < startTime || returnTime < signinTime) {
                errors.push('Please Check Return Time');
            }
        }

        setValidationErrors(errors);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Perform final validation before submission
        validateTimes(formData);

        // Wait for validationErrors to be updated
        if (validationErrors.length > 0) {
            alert('Please fix the following errors:\n' + validationErrors.join('\n'));
            return; // Prevent submission if errors exist
        }

        // Check if the required fields are filled
        if (
            !formData.timesheet_time_start ||
            !formData.timesheet_time_signin ||
            !formData.timesheet_time_signout ||
            !formData.timesheet_time_return
        ) {
            alert('Please fill in all time fields.');
            return;
        }

        // Show a prompt with the entered value to the user to confirm the time
        const confirmationMessage = `You entered the following times:\n
        Time Start: ${formData.timesheet_time_start}\n
        Time Sign In: ${formData.timesheet_time_signin}\n
        Time Sign Out: ${formData.timesheet_time_signout}\n
        Time Return: ${formData.timesheet_time_return}\n
        Expected Amount: ${formData.timesheet_expected_amount}\n
        Is Holiday: ${formData.timesheet_is_holiday ? 'Yes' : 'No'}`;

        const confirmed = window.confirm(confirmationMessage);

        if (confirmed) {
            try {
                const updatedFormData = {
                    ...formData,
                    timesheet_save_state: true,
                };

                const updatedTimesheet = await updateTimesheet(
                    timesheet.timesheet_id,
                    updatedFormData
                );

                alert('Timesheet updated successfully!');
                window.location.reload();
                onUpdate(updatedTimesheet);
            } catch (error) {
                console.error('Error updating timesheet:', error);
                alert(` ${error.message}`);
            } finally {
                onClosePopup();
            }
        }
    };

    return (
        <div className="box popup">
            <div className="popup-content">
                <form onSubmit={handleSubmit}>
                    <button className='fa fa-window-close' onClick={onClosePopup}></button>
                    <div className="form-group">
                        <label htmlFor="timesheet_time_start">Time Start</label><br></br>
                        <input
                            type="time"
                            className="form-control input-box"
                            id="timesheet_time_start"
                            name="timesheet_time_start"
                            value={formData.timesheet_time_start}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="timesheet_time_signin">Time Sign In</label><br></br>
                        <input
                            type="time"
                            className="form-control input-box"
                            id="timesheet_time_signin"
                            name="timesheet_time_signin"
                            value={formData.timesheet_time_signin}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="timesheet_time_signout">Time Sign Out</label><br></br>
                        <input
                            className="form-control input-box"
                            type="time"
                            id="timesheet_time_signout"
                            name="timesheet_time_signout"
                            value={formData.timesheet_time_signout}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="timesheet_time_return">Time Return</label><br></br>
                        <input
                            className="form-control input-box"
                            type="time"
                            id="timesheet_time_return"
                            name="timesheet_time_return"
                            value={formData.timesheet_time_return}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="timesheet_expected_amount">Expected Amount</label>
                        <input
                            className="form-control input-box"
                            type="number"
                            id="timesheet_expected_amount"
                            name="timesheet_expected_amount"
                            value={formData.timesheet_expected_amount}
                            onChange={handleChange}
                        />
                    </div>
                    <br></br>

                    <div>
                        <input
                            type="checkbox"
                            id="timesheet_is_holiday"
                            name="timesheet_is_holiday"
                            checked={formData.timesheet_is_holiday}
                            onChange={handleChange}
                        />
                        <label htmlFor="timesheet_is_holiday">Is Holiday</label>
                    </div>

                    <div className="form-group">
                        <button
                            type="submit"
                            className="btn1">
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default UpdateTimesheetForm;





// import React, { useEffect, useState } from "react";
// import { updateTimesheet } from "../core/helper/coreapicalls";

// const UpdateTimesheetForm = ({ timesheet, onUpdate, onClosePopup }) => {
//   const [formData, setFormData] = useState({
//     timesheet_time_start: "",
//     timesheet_time_signin: "",
//     timesheet_time_signout: "",
//     timesheet_time_return: "",
//     timesheet_expected_amount: 0,
//     timesheet_is_holiday: false,
//   });

//   const [validationErrors, setValidationErrors] = useState([]);
//   useEffect(() => {
//     setFormData({
//       timesheet_time_start: timesheet.timesheet_time_start || "",
//       timesheet_time_signin: timesheet.timesheet_time_signin || "",
//       timesheet_time_signout: timesheet.timesheet_time_signout || "",
//       timesheet_time_return: timesheet.timesheet_time_return || "",
//       timesheet_expected_amount: timesheet.timesheet_expected_amount || 0,
//       timesheet_is_holiday: timesheet.timesheet_is_holiday || false,
//     });
//     // setValidationErrors(validateTimes(formData)); // Call validateTimes on initial render and after formData changes
//   }, [timesheet,formData]);

//   const handleChange = (e) => {
//     const { name, value, type, checked } = e.target;
//     // Check if the input is a checkbox
//     const updatedValue = type === "checkbox" ? checked : value;
//     const formattedTime = value ? `${value}:00` : null;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: type === "time" ? formattedTime : updatedValue,
//     }));
//     validateTimes();
//   };

//   const validateTimes = () => {
//     const errors = [];
//     if (formData.timesheet_time_signin && formData.timesheet_time_start) {
//       const startTime = new Date(`1970-01-01T${formData.timesheet_time_start}`);
//       const signinTime = new Date(
//         `1970-01-01T${formData.timesheet_time_signin}`
//       );
//       if (signinTime < startTime) {
//         errors.push("Sign In Time cannot be before Start Time.");
//       }
//     }

//     if (formData.timesheet_time_signout && formData.timesheet_time_signin) {
//       const signinTime = new Date(
//         `1970-01-01T${formData.timesheet_time_signin}`
//       );
//       const signoutTime = new Date(
//         `1970-01-01T${formData.timesheet_time_signout}`
//       );
//       const startTime = new Date(`1970-01-01T${formData.timesheet_time_start}`);
//       if (signoutTime < signinTime || signoutTime < startTime) {
//         errors.push("Please check Sign out time");
//       }
//     }

//     if (formData.timesheet_time_return && formData.timesheet_time_signout) {
//       const signinTime = new Date(
//         `1970-01-01T${formData.timesheet_time_signin}`
//       );
//       const startTime = new Date(`1970-01-01T${formData.timesheet_time_start}`);
//       const signoutTime = new Date(
//         `1970-01-01T${formData.timesheet_time_signout}`
//       );
//       const returnTime = new Date(
//         `1970-01-01T${formData.timesheet_time_return}`
//       );

//       if (
//         returnTime < signoutTime ||
//         returnTime < startTime ||
//         returnTime < signinTime
//       ) {
//         errors.push("Please Check Return Time ");
//       }
//     }

//     setValidationErrors(errors); // Update errors state
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setValidationErrors([]);
//     // Perform final validation before submission
//     validateTimes();
//     if (validationErrors.length > 0) {
//       alert("Please fix the following errors:\n" + validationErrors.join("\n"));
//       return; // Prevent submission if errors exist
//     }
//     // Check if the required fields are filled
//     if (
//       !formData.timesheet_time_start ||
//       !formData.timesheet_time_signin ||
//       !formData.timesheet_time_signout ||
//       !formData.timesheet_time_return
//     ) {
//       alert("Please fill in all time fields.");
//       return;
//     }
//     // Show a prompt with the entered value to the user to confirm the time
//     const confirmationMessage = `You entered the following times:\n
//         Time Start: ${formData.timesheet_time_start}\n
//         Time Sign In: ${formData.timesheet_time_signin}\n
//         Time Sign Out: ${formData.timesheet_time_signout}\n
//         Time Return: ${formData.timesheet_time_return}\n
//         Expected Amount: ${formData.timesheet_expected_amount}\n
//         Is Holiday: ${formData.timesheet_is_holiday ? "Yes" : "No"}`;

//     const confirmed = window.confirm(confirmationMessage);

//     if (confirmed) {
//       try {
//         const updatedFormData = {
//           ...formData,
//           timesheet_save_state: true,
//         };
//         const updatedTimesheet = await updateTimesheet(
//           timesheet.timesheet_id,
//           updatedFormData
//         );
//         alert("Timesheet updated successfully!");
//         window.location.reload();
//         onUpdate(updatedTimesheet);
//       } catch (error) {
//         console.error("Error updating timesheet:", error);
//         alert(` ${error.message}`);
//       } finally {
//         onClosePopup();
//       }
//     }
//   };

//   return (
//     <div className="box popup">
//       <div className="popup-content">
//         <form onSubmit={handleSubmit}>
//           <button
//             className="fa fa-window-close"
//             onClick={onClosePopup}
//           ></button>
//           <div className="form-group ">
//             <label htmlFor="timesheet_time_start">Time Start</label>
//             <br></br>
//             <input
//               type="time"
//               className="form-control input-box"
//               id="timesheet_time_start"
//               name="timesheet_time_start"
//               value={formData.timesheet_time_start}
//               onChange={handleChange}
//             />
//           </div>

//           <div className="form-group ">
//             <label htmlFor="timesheet_time_signin">Time Sign In</label>
//             <br></br>
//             <input
//               type="time"
//               className="form-control input-box"
//               id="timesheet_time_signin"
//               name="timesheet_time_signin"
//               value={formData.timesheet_time_signin}
//               onChange={handleChange}
//             />
//           </div>
//           <div className="form-group ">
//             <label htmlFor="timesheet_time_signout">Time Sign Out</label>
//             <br></br>
//             <input
//               className="form-control input-box"
//               type="time"
//               id="timesheet_time_signout"
//               name="timesheet_time_signout"
//               value={formData.timesheet_time_signout}
//               onChange={handleChange}
//             />
//           </div>

//           <div className="form-group ">
//             <label htmlFor="timesheet_time_return">Time Return</label>
//             <br></br>
//             <input
//               className="form-control input-box"
//               type="time"
//               id="timesheet_time_return"
//               name="timesheet_time_return"
//               value={formData.timesheet_time_return}
//               onChange={handleChange}
//             />
//           </div>

//           <div className="form-group ">
//             <label htmlFor="timesheet_expected_amount">Expected Amount</label>
//             <input
//               className="form-control input-box"
//               type="number"
//               id="timesheet_expected_amount"
//               name="timesheet_expected_amount"
//               value={formData.timesheet_expected_amount}
//               onChange={handleChange}
//             />
//           </div>
//           <br></br>

//           <div>
//             <input
//               type="checkbox"
//               id="timesheet_is_holiday"
//               name="timesheet_is_holiday"
//               checked={formData.timesheet_is_holiday}
//               onChange={handleChange}
//             />
//             <label htmlFor="timesheet_is_holiday">Is Holiday</label>
//           </div>

//           <div className="form-group">
//             <button type="submit" className="btn1 ">
//               Submit
//             </button>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default UpdateTimesheetForm;
