import React from 'react';


const Footer = ({ boxShadow }) => {
  return (
    <div className="footer-box" style={{ boxShadow }}>
      <div className="container container-fluid">
        <div className="row d-flex flex-column flex-md-row justify-content-between align-items-center">
          <div className="col-md-4">
            <h5>Contact Us</h5>
            <p>
              Email: <a href="mailto:contact@example.com" className="text-info">service@maritech-solutions.com</a>
            </p>
            <p>Phone: +971 524 382 709</p>
            
          </div>
          {/* <div className="col-md-4 ">
          <WhatsAppButton></WhatsAppButton>
          </div> */}

          <div className="col-md-4 text-md-end mt-3 mt-md-0">
            
            <h6>Follow Us &nbsp;&nbsp;&nbsp;

            <a href="https://ae.linkedin.com/company/maritech-solutions-worldwide" >
              
                <i className="fa fa-linkedin" aria-hidden="true">  </i>
                </a>
            </h6>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

