// Pagination.js

import React from 'react';

const Pagination = ({ currentPage, totalPages, onNextPage, onPrevPage }) => {
  return (
    <div className="pagination-controls">
      <button
        type="button"
        className="btn btn-secondary m-2"
        onClick={onPrevPage}
        disabled={currentPage === 1}
      >
        Previous
      </button>
      <span className="m-2" style={{color:'black'}}>{`Page ${currentPage} of ${totalPages}`}</span>
      <button
        type="button"
        className="btn btn-secondary m-2"
        onClick={onNextPage}
        disabled={currentPage === totalPages}
      >
        Next
      </button>
    </div>
  );
};

export default Pagination;
