
import React, { useState, useEffect } from 'react';

const BetaMessage = ({ onClose }) => {
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    const hasShownMessage = sessionStorage.getItem('hasShownMessage');
    if (!hasShownMessage) {
      setShowMessage(true);
      sessionStorage.setItem('hasShownMessage', 'true');
    }
  }, []);

  const handleClose = () => {
    setShowMessage(false);
    onClose();
  };

  return (
    <>
      {showMessage && (
        <div style={{ background: '#f8d7da', color: '#721c24', padding: '20px', margin: '20px 0', border: '1px solid #f5c6cb', borderRadius: '5px' }}>
        <p><strong><span role="img" aria-label="rocket">🚀</span> Welcome to Our Beta Release!</strong></p>
        <p>We're excited to have you on board for testing our new overtime and worktime management webapp! This is a beta version, and your feedback is crucial for making it better.</p>
        <p>If you encounter any bugs, issues, or have suggestions, please report them immediately. <span role='img' aria-label='bug'>🐛</span> Help us improve by providing detailed information and, if possible, include a screenshot.</p>
        <p>Click the Whatsapp button in the app or send an email to <a href="mailto:dev.mastersystems@gmail.com">dev.mastersystems@gmail.com</a>.</p>
        <p>Thank you for being part of our journey!</p>
        <button className='btn btn-secondary' onClick={handleClose} style={{ marginTop: '10px', cursor: 'pointer' }}>Close</button>
      </div>
      )}
    </>
  );
};

export default BetaMessage;





