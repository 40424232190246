import React, { useState } from 'react';
import UpdateTimesheetForm from './UpdateTimesheetForm';
import { useNavigate } from 'react-router-dom';

const TimesheetNotSavedList = ({ timesheetData }) => {
  console.log("props:",  timesheetData)
  const [selectedTimesheet, setSelectedTimesheet] = useState(null);
  const navigate = useNavigate();

  const handleUpdateClick = (timesheet) => {
    setSelectedTimesheet(timesheet);
  };
  return (
    <div>
      <div >
      <table className="table ">
        <thead>
          <tr>
            <th>Date</th>
            <th>Vessel</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {timesheetData.map((timesheet) => (
            <tr key={timesheet.timesheet_id}>
              <td>{timesheet.timesheet_date}</td>
              <td>{timesheet.timesheet_vessel_name}</td>
              <td>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => handleUpdateClick(timesheet)}
                >
                  Update
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
      {selectedTimesheet && (
        <UpdateTimesheetForm
          timesheet={selectedTimesheet}
          onUpdate={() => navigate('/user/dashboard')}
          onClosePopup={() => setSelectedTimesheet(null) }
        />
      )}
    </div>
  );
};

export default TimesheetNotSavedList;






