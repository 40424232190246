// import { Form , useNavigate } from "react-router-dom";
import { API } from "../../backend"


export const signup = user => {
    console.log("Test user object", user)
    // Destructure user object
    const { emp_name, emp_email, emp_msid, emp_branch_id, emp_phone, emp_gender, emp_country_id, password, } = user;
    const is_active = false;
    console.log("test strings", JSON.stringify({ emp_name, emp_email, password, emp_branch_id, emp_country_id, emp_msid, emp_phone, emp_gender, is_active }))

    return fetch(`${API}user/`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        // Update request body properties to match backend expectations
        body: JSON.stringify({ emp_name, emp_email, password, emp_branch_id, emp_country_id, emp_msid, emp_phone, emp_gender, is_active })
    })
        .then((response) => {
            return response.json();
        })
        .catch((err) => console.log(err));
};


export const signin = async (user) => {
    const formData = new FormData();

    for (const name in user) {
        formData.append(name, user[name]);
    }

    try {
        const response = await fetch(`${API}user/api/token/`, {
            method: "POST",
            body: formData,
        });

        if (response.ok) {
            const jsonData = await response.json();
            const userId = jsonData.id;

            const teamResponse = await fetch(`${API}department/team/`);

            if (teamResponse.ok) {
                let teams;
                try {
                    const responseData = await teamResponse.json();
                    // console.log("check team data in index", responseData)
                    teams = responseData; // Extract array from the results property
                } catch (error) {
                    console.error("Error parsing team data JSON:", error);
                    throw error;
                }
                
                if (Array.isArray(teams)) {
                    const userTeamLead = teams.find(team => team.team_lead === userId && team.team_is_tl_active);
                    const userTeamSubLead = teams.find(team => team.team_sublead === userId && team.team_is_sl_active);

                    jsonData.tl = !!userTeamLead; // Convert to boolean
                    jsonData.sl = !!userTeamSubLead; // Convert to boolean

                    jsonData.team_id_tl = userTeamLead ? userTeamLead.team_id : null;
                    jsonData.team_id_sl = userTeamSubLead ? userTeamSubLead.team_id : null;

                    return jsonData;
                } else {
                    console.error("Team data is not an array:", teams);
                }
            } else {
                console.error("Error fetching team data. HTTP status:", teamResponse.status);
            }
        } else {
            console.error("Sign-in failed. HTTP status:", response.status);
            try {
                const errorData = await response.json();
                if (errorData.detail === "No active account found with the given credentials") {
                    alert("Invalid Credentials or Account Deactivated");
                } else {
                    console.error("Sign-in error details:", errorData);
                }
            } catch (jsonError) {
                console.error("Unable to parse error details:", jsonError);
            }
        }
    } catch (error) {
        console.error("Error during sign-in:", error);
        // Handle network errors or other issues
        throw error;
    }
};




export const authenticate = (data, next) => {
    
    if (typeof window !== undefined) {
        // Omitting data.access and data.refresh
        const { access, refresh, ...cleanedData } = data;

        localStorage.setItem("jwt", JSON.stringify(cleanedData));
        localStorage.setItem("access", JSON.stringify(data.access));
        localStorage.setItem("refresh", JSON.stringify(data.refresh));
        next();
    }
};


export const getToken = () => {
    if (typeof window === 'undefined') {
        return false;
    }

    const accessToken = localStorage.getItem("access");
    const refreshToken = localStorage.getItem("refresh");

    if (accessToken && refreshToken) {
        // TODO: Compare tokens with database JSON tokens if needed
        return { access: JSON.parse(accessToken), refresh: JSON.parse(refreshToken) };
    } else {
        return false;
    }
};


export const isAuthenticated = () => {
    if (typeof window == undefined) {
        return false
    }
    if (localStorage.getItem("jwt")) {
        return JSON.parse(localStorage.getItem("jwt"));
        //TOTO: compare JWT with database json token
    } else {
        return false;
    }

};

let isAlertShown = false; // Flag to track if the alert has been shown

export const refreshAccessToken = async (refreshToken) => {
//   console.log("checking refresh token", JSON.stringify({ refresh: refreshToken }))
  try {
    // Make a request to your backend to refresh the access token
    const response = await fetch(`${API}user/api/token/refresh/`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ refresh: refreshToken }),
    });

    if (!response.ok) {
      // Handle error response
      if (response.status === 401) {
        if (!isAlertShown) { // Check if the alert has already been shown
          isAlertShown = true; // Set the flag to true to prevent subsequent alerts
          signout(() => {
            window.location.href = "/";
            alert('Session Expired! Please Login now');
          });
        }
      }
      throw new Error(`Failed to refresh access token: ${response.status}`);
    }

    // Parse the JSON response
    const resultToken = await response.json();
    console.log("new token received:", resultToken)

    // Save the new tokens to localStorage
    saveTokensToLocalStorage(resultToken.access, resultToken.refresh);

    return resultToken.access;
  } catch (error) {
    throw error;
  }
};

  

const saveTokensToLocalStorage = (accessToken, refreshToken) => {
    if (typeof window !== undefined) {

        localStorage.setItem("access", JSON.stringify(accessToken));
        localStorage.setItem("refresh", JSON.stringify(refreshToken));
       
    }
  };

export const userRole = () => {
    if (typeof window === 'undefined') {
        return false;
    }

    if (localStorage.getItem('jwt')) {
        const jwtPayload = JSON.parse(localStorage.getItem('jwt'));
        // console.log("jwtPayload in local storage", jwtPayload)

        // Check if 'user' key exists and is an object
        if (jwtPayload && typeof jwtPayload === 'object') {
            // Extract boolean fields starting with 'emp_is_' from the 'user' object
            const userRoles = Object.keys(jwtPayload).filter(
                key =>
                    (key.startsWith('emp_is_') || key === 'is_superuser') &&
                    typeof jwtPayload[key] === 'boolean' &&
                    jwtPayload[key] === true
            );

            // Add 'tl' role if tl is true
            if (jwtPayload.tl === true) {
                userRoles.push('tl');
            }

            // Add 'sl' role if sl is true
            if (jwtPayload.sl === true) {
                userRoles.push('sl');
            }

            // Return an array of roles
            return userRoles;
        }
    }
    return false;
};

export const getEmpDateAllowed = () => {
    if (typeof window === 'undefined') {
        return false;
    }

    if (localStorage.getItem('jwt')) {
        const jwtPayload = JSON.parse(localStorage.getItem('jwt'));

        // Check if 'user' key exists and is an object
        if (jwtPayload && typeof jwtPayload === 'object') {
            // Extract the value of the 'emp_date_allowed' key from the 'user' object
            const empDateAllowed = jwtPayload['emp_date_allowed'];

            // Check if 'emp_date_allowed' is a number
            if (typeof empDateAllowed === 'number') {
                // Return the value of 'emp_date_allowed'
                return empDateAllowed;
            }
        }
    }
    return false;
};

export const signout = next => {
    const userId = isAuthenticated() && isAuthenticated().id
    if (typeof window !== undefined) {
        localStorage.removeItem("access")
        localStorage.removeItem("refresh")
        localStorage.removeItem("jwt")
        next();
        return fetch(`${API}user/logout/${userId}`, {
            method: "GET"
        })
            .then((response) => {
                console.log("signout Success");
                next();
            })
            .catch((err) => console.log(err));
    }
}




