


import React, { useEffect, useState } from "react";
import { getCountries } from '../core/helper/coreapicalls';

const CountryDropdown = ({ onSelectCountry, defaultValue }) => {


    const [countryData, setCountryData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getCountries();
                setCountryData(data);
            } catch (error) {
                setError(error.message || "An error occurred");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);
    

    const handleCountryChange = (event) => {
        const selectedCountryId = event.target.value;
        const country = countryData.find((b) => b.country_id === parseInt(selectedCountryId));
        onSelectCountry(country); // Pass the selected country to the parent component
    };

    return (
        <div style={{minWidth:229}}>
            {loading && <p>Loading...</p>}
            {error && <p>Error: {error}</p>}
            {countryData.length > 0 && (
                <select className="form-control input-box" onChange={handleCountryChange} value={defaultValue && typeof defaultValue === 'object' ? defaultValue.country_id || '' : defaultValue || ''} required>
                    <option value="" disabled>Select a Country</option>
                    {countryData.map((country) => (
                        <option key={country.country_id} value={country.country_id}>
                            {country.country_name}
                        </option>
                    ))}
                </select>
            )}
        </div>
    );
};

export default CountryDropdown;
