// // WorkDetails.js
// import React from 'react';

// const WorkDetails = ({ workDetails }) => {
//   return (
//     <div className='box m-2 p-8' >
//       {workDetails.work_country_id && (
//         <div >
//           <label>Country: {workDetails.work_country_id}</label>
//         </div>
//       )}
//       {workDetails.work_dept_id && (
//         <div>
//           <label>Department: {workDetails.work_dept_id} </label>
//         </div>
//       )}
//       {workDetails.work_branch_id && (
//         <div>
//           <label>Branch: {workDetails.work_branch_id}</label>
//         </div>
//       )}
//       {workDetails.work_vessel && (
//         <div>
//           <label>Vessel: {workDetails.work_vessel}</label>
//         </div>
//       )}
//       {workDetails.work_vessel_imo && (
//         <div>
//           <label>Vessel IMO: {workDetails.work_vessel_imo}</label>
//         </div>
//       )}
//       {workDetails.work_job_number && (
//         <div>
//           <label>Job Number: {workDetails.work_job_number}</label>
//         </div>
//       )}
//       {workDetails.work_job_description && (
//         <div>
//           <label>Job Description: {workDetails.work_job_description}</label>
//         </div>
//       )}
//       {workDetails.work_job_pic_name && (
//         <div>
//           <label>Job Pic Name: {workDetails.work_job_pic_name}</label>
//         </div>
//       )}
//       {workDetails.work_location && (
//         <div>
//           <label>Location: {workDetails.work_location}</label>
//         </div>
//       )}
//       {workDetails.work_type && (
//         <div>
//           <label>Type: {workDetails.work_type}</label>
//         </div>
//       )}
//       {workDetails.work_active && (
//         <div>
//           <label>Active: {workDetails.work_active}</label>
//         </div>
//       )}
//     </div>
//   );
// };

// export default WorkDetails;


import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const WorkDetails = ({ workDetails }) => {
  const filteredDetails = Object.fromEntries(
    Object.entries(workDetails).filter(
      ([key]) =>
        !['created_at', 'updated_at', 'work_job_pic_name', 'work_id', 'work_updated_emp','work_added_emp','work_dept_id','work_active','work_branch_id', 'work_country_id'].includes(key)
    )
  );

  return (
    <div className="container  d-flex justify-content-center">
      <table className="table table-bordered table-striped " style={{maxWidth:1000}}>
        <thead >
          <tr>
            <th>Field</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(filteredDetails).map(([key, value]) => (
            <tr key={key}>
              <td>{key.replace('work_', '').replace('_', ' ')}</td>
              <td>{value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default WorkDetails;