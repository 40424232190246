import React, { useState } from "react";
import Base from "../core/Base";
import { Link } from "react-router-dom";
import { signup } from "../auth/helper";
import BranchDropdown from "./BranchDropdown";
import CountryDropdown from "./CountryDropdown";

const Signup = () => {
  const [values, setValues] = useState({
    emp_name: "",
    emp_email: "",
    emp_msid: "",
    emp_phone: "",
    emp_gender: "",
    password: "",
    confirmPassword: "",
    selectedBranch: null,
    selectedCountry: null,
    errors: {},
  });

  const { 
    emp_name, 
    emp_email, 
    emp_msid, 
    emp_phone, 
    emp_gender, 
    password, 
    confirmPassword, 
    selectedBranch, 
    selectedCountry, 
    errors 
  } = values;

  const handleChange = (name) => (event) => {
    const value = event.target.value;
  
    if (name === "password") {
      const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z]).{6,}$/;
      const isValidPassword = passwordRegex.test(value);
  
      setValues({
        ...values,
        [name]: value,
        errors: {
          ...errors,
          password: isValidPassword
            ? ""
            : "Password must be at least 6 characters with 1 uppercase letter and 1 lowercase letter",
        },
      });
    } else {
      setValues({ ...values, [name]: value });
    }
  };
  

  const handleBlur = (name) => () => {
    if (!values[name].trim()) {
      setValues({ ...values, errors: { ...errors, [name]: `${name.charAt(4).toUpperCase() + name.slice(5)} is required` } });
    } else {
      setValues({ ...values, errors: { ...errors, [name]: "" } });
    }
  };

  const handleBranchSelect = (branch) => {
    setValues({ ...values, selectedBranch: branch });
  };

  const handleCountrySelect = (country) => {
    setValues({ ...values, selectedCountry: country });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const newErrors = {};

    if (!emp_name.trim()) {
      newErrors.emp_name = "Name is required";
    }

    if (!emp_email.trim()) {
      newErrors.emp_email = "Email is required";
    }

    if (!emp_msid.trim()) {
      newErrors.emp_msid = "MSID is required";
    }

    if (!emp_phone.trim()) {
      newErrors.emp_phone = "Phone is required";
    }

    if (!emp_gender.trim()) {
      newErrors.emp_gender = "Gender is required";
    }

    if (!password.trim()) {
      newErrors.password = "Password is required";
    }

    if (!confirmPassword.trim()) {
      newErrors.confirmPassword = "Confirm Password is required";
    }

    if (password !== confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }

    if (!selectedBranch) {
      newErrors.branch = "Please select a branch";
    }

    if (!selectedCountry) {
      newErrors.country = "Please select a country";
    }

    // Set errors and stop submission if there are validation errors
    if (Object.keys(newErrors).length > 0) {
      setValues({ ...values, errors: newErrors });
      return;
    }

    // Proceed with signup
    signup({
      emp_name,
      emp_email,
      emp_msid,
      emp_phone,
      emp_gender,
      password,
      emp_branch_id: selectedBranch.branch_id,
      emp_country_id: selectedCountry.country_id,
    })
      .then((data) => {
        if (data.emp_email === emp_email) {
          alert("Registration Success!");
          setValues({
            ...values,
            emp_name: "",
            emp_email: "",
            emp_msid: "",
            emp_phone: "",
            emp_gender: "",
            password: "",
            confirmPassword: "",
            selectedBranch: null,
            selectedCountry: null,
            errors: {},
          });
        } else {
          setValues({
            ...values,
            errors: { ...errors, emp_email: data.emp_email[0] },
          });
        }
      })
      .catch((e) => console.log(e));
  };

  return (
    <Base title="Sign-up" description="Maritech Solutions" mobileBgImage="" desktopBgImage="">
      <div className="row">
        <div className="col-md-6 offset-sm-3 text-left">
          <form className="box" onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Name</label>
              <input
                className="form-control input-box"
                value={emp_name}
                onChange={handleChange("emp_name")}
                onBlur={handleBlur("emp_name")}
                type="text"
                required
              />
              {errors.emp_name && <small className="text-danger">{errors.emp_name}</small>}
            </div>
            <div className="form-group ">
              <label>Email</label>
              <input
                className="form-control input-box"
                value={emp_email}
                onChange={handleChange("emp_email")}
                onBlur={handleBlur("emp_email")}
                type="email"
                required
              />
              {errors.emp_email && <small className="text-danger">{errors.emp_email}</small>}
            </div>
            <div className="form-group">
              <label>MSID</label>
              <input
                className="form-control input-box"
                value={emp_msid}
                onChange={handleChange("emp_msid")}
                onBlur={handleBlur("emp_msid")}
                type="text"
                required
              />
              {errors.emp_msid && <small className="text-danger">{errors.emp_msid}</small>}
            </div>
            <div className="form-group">
              <label>Phone</label>
              <input
                className="form-control input-box"
                value={emp_phone}
                onChange={handleChange("emp_phone")}
                onBlur={handleBlur("emp_phone")}
                type="text"
                required
              />
              {errors.emp_phone && <small className="text-danger">{errors.emp_phone}</small>}
            </div>
            <div className="form-group">
              <label>Gender</label>
              <select
                className="form-control input-box"
                value={emp_gender}
                onChange={handleChange("emp_gender")}
                onBlur={handleBlur("emp_gender")}
                required
              >
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
              {errors.emp_gender && <small className="text-danger">{errors.emp_gender}</small>}
            </div>
            <div className="form-group">
              <label>Password</label>
              <input
                className="form-control input-box"
                value={password}
                onChange={handleChange("password")}
                onBlur={handleBlur("password")}
                type="password"
                required
              />
              {errors.password && <small className="text-danger">{errors.password}</small>}
            </div>
            <div className="form-group">
              <label>Confirm Password</label>
              <input
                className="form-control input-box"
                value={confirmPassword}
                onChange={handleChange("confirmPassword")}
                onBlur={handleBlur("confirmPassword")}
                type="password"
                required
              />
              {errors.confirmPassword && <small className="text-danger">{errors.confirmPassword}</small>}
            </div>
            <div className="form-group ">
              <label>Country</label>
              <CountryDropdown
                onSelectCountry={handleCountrySelect}
                defaultValue={selectedCountry}
                onBlur={handleBlur("emp_country")}
                style={{ width: "100%" }}
              />
              {errors.country && <small className="text-danger">{errors.country}</small>}
            </div>
            <div className="form-group">
              <label>Branch</label>
              <BranchDropdown
                onSelectBranch={handleBranchSelect}
                onBlur={handleBlur("emp_branch")}
                style={{ width: "100%" }}
              />
              {errors.branch && <small className="text-danger">{errors.branch}</small>}
            </div>
            <div className="form-group">
              <button type="submit" className="btn1 btn-success btn-block ">
                Submit
              </button>
            </div>
            <div style={{minHeight:30}}></div>
            <div className="text-center" style={{ color: "black" }}>
              <Link to="/signin">Signin</Link> now!
            </div>
          </form>
        </div>
        <div style={{minHeight:30}}></div>
      </div>
    </Base>
  );
};

export default Signup;
