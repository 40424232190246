import React, { useState, useEffect } from 'react';
import DetailedUserView from './DetailedUserView';
import { getAllTimesheets, updateTimesheetProcessData } from './helper/coreapicalls';
import Pagination from './Pagination'; // Import the Pagination component

const TeamLeadControl = (onClosePopup) => {
  const [selectedTimesheet, setSelectedTimesheet] = useState(null);
  const [timesheetsData, setTimesheetsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  // const [processStatus, setProcessStatus] = useState(0)
  const [loading, setLoading] = useState(false)
  const loadingMessage = () => {
    return (
      loading && (
        <div className="overlay">
          <div className="d-flex justify-content-center align-items-center vh-100">
            <div className="spinner-border text-danger" role="status"></div>
          </div>
        </div>
      )
    );
  };
  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]); // Fetch data when currentPage changes
// console.log("Admin approval working")
  const fetchData = async (page) => {
    setLoading(true);
    const response = await getAllTimesheets(page);
    setTimesheetsData(response.timesheetsData);
    setTotalPages(Math.ceil(response.count / 100)); 
    setLoading(false);
  };
  const handleViewClick = (timesheet) => {
    setSelectedTimesheet(timesheet);
  };

  const handleProcessClick = async (timesheet) => {
    try {
      setLoading(true);
      const updatedTimesheet = { ...timesheet };
      updatedTimesheet.timesheet_final_process_status = !timesheet.timesheet_final_process_status;
      const jsonData = {
        timesheet_emp_id: timesheet.timesheet_emp_id,
        timesheet_final_process_status: updatedTimesheet.timesheet_final_process_status
      };
      await updateTimesheetProcessData(timesheet.timesheet_id, jsonData);
      // Update the timesheet data in the state to reflect the changes
      setTimesheetsData(prevData =>
        prevData.map(item =>
          item.timesheet_id === timesheet.timesheet_id ? updatedTimesheet : item
        )
      );
      // Update processStatus state to track the state change
      // setProcessStatus(prevStatus => prevStatus === 0 ? 1 : 0);
      setLoading(false);
    } catch (error) {
      console.error('Error updating timesheet:', error);
      setLoading(false);
    }
  };
  const handleSaveEdit = (editedTimesheet) => {
    setTimesheetsData((prevData) =>
      prevData.map((timesheet) =>
        timesheet.timesheet_id === editedTimesheet.timesheet_id ? editedTimesheet : timesheet
      )
    );
    setSelectedTimesheet(null);
  };

  const handleCloseEdit = () => {
    setSelectedTimesheet(0);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  return (
    <div className="row">
      {loading&& loadingMessage()}

      <div className="table-container" style={{ maxHeight: '500px', overflowY: 'auto' }}>
        <table className="table table-striped">
          <thead style={{ position: 'sticky', top: 0, zIndex: 1, background: 'yellow ' }}>
            <tr style={{maxHeight:30, fontSize :12, alignContent:'center',  textAlign:'center'}}>
              <th >View</th>
              <th >Date<br/> Y-M-D</th>
              <th >Eng Name</th>
              <th >Start Time</th>
              <th >Sign In</th>
              <th >Sign Out</th>
              <th >Return</th>
              <th >Normal Hrs</th>
              <th >Total Hrs</th>
              <th >OT Hrs</th>
              <th >Exp OT</th>
              <th >OT Amount</th>
              <th >Allowance</th>
              <th >Total</th>
              <th >Holiday</th>
              <th >Anch</th>
              <th >Overseas</th>
              <th>DDock</th>
              <th >Team Lead</th>
              <th >Dept Mgr</th>
              <th >Final Status</th>
              <th >Process Status</th>
              <th >Comments</th>
            </tr>
          </thead>
          <tbody>
            {timesheetsData.map((timesheet) => (
              <tr key={timesheet.timesheet_id}>
                <td>
                  <button type="button" className="btn fa fa-eye" style={{ color:'blue', fontSize:20}} onClick={() => handleViewClick(timesheet)}> </button>
                </td>
                <td style={{alignContent:'center',  textAlign:'center'}} >{timesheet.timesheet_date}</td>
                <td style={{alignContent:'center',  textAlign:'center'}}>{timesheet.emp_name}</td>
                <td style={{alignContent:'center',  textAlign:'center'}}>{timesheet.timesheet_time_start}</td>
                <td style={{alignContent:'center',  textAlign:'center'}}>{timesheet.timesheet_time_signin}</td>
                <td style={{alignContent:'center',  textAlign:'center'}}>{timesheet.timesheet_time_signout}</td>
                <td style={{alignContent:'center',  textAlign:'center'}}>{timesheet.timesheet_time_return}</td>
                <td style={{alignContent:'center',  textAlign:'center'}}>{timesheet.timesheet_normal_hours}</td>
                <td style={{alignContent:'center',  textAlign:'center'}}>{timesheet.timesheet_total_hours}</td>
                <td style={{alignContent:'center',  textAlign:'center',fontWeight:'bold'}}>{timesheet.timesheet_over_time_hours}</td>
                <td style={{alignContent:'center',  textAlign:'center'}}>{timesheet.timesheet_expected_amount}</td>
                <td style={{alignContent:'center',  textAlign:'center'}}>{timesheet.timesheet_overtime_amount}</td>
                <td style={{alignContent:'center',  textAlign:'center'}}>{timesheet.timesheet_allowance_amount}</td>
                <td style={{alignContent:'center',  textAlign:'center', fontWeight:'bold'}}>{timesheet.timesheet_Total_amount}</td>
                <td style={{alignContent:'center',  textAlign:'center'}}>{timesheet.timesheet_is_holiday ? (
                    <span className= "fa fa-check badge text-white bg-success m-2" style={{alignContent:'center',  textAlign:'center'}} aria-hidden="true"  >
                      &nbsp;
                    </span>
                  ) : (
                    <span className= "fa fa-times badge text-dark bg-warning m-2" style={{alignContent:'center',  textAlign:'center'}} aria-hidden="true"  >
                      &nbsp;
                    </span>
                  )}</td>
                {/* Anchorage check */}
                 <td style={{ alignContent: 'center', textAlign: 'center' }}>{timesheet.timesheet_attendance_id.attendance_is_anchorage ? (
                  <span className="fa fa-check badge text-white bg-success m-2" style={{ alignContent: 'center', textAlign: 'center' }} aria-hidden="true"  >
                    &nbsp;
                  </span>
                ) : (
                  <span className="fa fa-times badge text-white bg-secondary m-2" style={{ alignContent: 'center', textAlign: 'center' }} aria-hidden="true"  >
                    &nbsp;
                  </span>
                )}
                </td>
                
                {/* Overseas Check */}

                <td style={{ alignContent: 'center', textAlign: 'center' }}>{timesheet.timesheet_attendance_id.attendance_is_overseas ? (
                  <span className="fa fa-check badge text-white bg-success m-2" style={{ alignContent: 'center', textAlign: 'center' }} aria-hidden="true"  >
                    &nbsp;
                  </span>
                ) : (
                  <span className="fa fa-times badge text-white bg-secondary m-2" style={{ alignContent: 'center', textAlign: 'center' }} aria-hidden="true"  >
                    &nbsp;
                  </span>
                )}
                </td>


                                {/* Drydock Check */}
                                <td style={{ alignContent: 'center', textAlign: 'center' }}>{timesheet.timesheet_attendance_id.attendance_is_dubaidrydock ? (
                  <span className="fa fa-check badge text-white bg-success m-2" style={{ alignContent: 'center', textAlign: 'center' }} aria-hidden="true"  >
                    &nbsp;
                  </span>
                ) : (
                  <span className="fa fa-times badge text-white bg-secondary m-2" style={{ alignContent: 'center', textAlign: 'center' }} aria-hidden="true"  >
                    &nbsp;
                  </span>
                )}
                </td>
                <td style={{ alignContent: 'center', textAlign: 'center' }}>
                  {/* teamlead approve */}
                  {timesheet.timesheet_teamlead_approve ? (
                    <span className="fa-solid fa-thumbs-up badge bg-success m-2 " >
                      &nbsp;
                    </span>
                  ) : (
                    <span className="badge bg-warning text-dark m-2 fa-solid fa-thumbs-down" >
                      &nbsp;
                    </span>
                  )}
                </td>
                <td style={{ alignContent: 'center', textAlign: 'center' }}>
                  {/* teamlead approve */}
                  {timesheet.timesheet_dept_manager_approve ? (
                    <span className="fa-solid fa-thumbs-up badge bg-success m-2 " >
                      &nbsp;
                    </span>
                  ) : (
                    <span className="badge bg-warning text-dark m-2 fa-solid fa-thumbs-down" >
                      &nbsp;
                    </span>
                  )}
                </td>
                <td>
                  {!timesheet.timesheet_dept_manager_approve ? (
                    <button type="button" className="btn btn-outline-danger p-1 m-1" style={{maxHeight:30, fontSize :13}} >
                      Waiting
                    </button>
                  ) : (
                    <button type="button" className="btn btn-outline-success p-1 m-1" style={{maxHeight:30, fontSize :13}}>
                      Accepted
                    </button>
                  )}
                </td>
                <td>
                        {!timesheet.timesheet_final_process_status ? (
                          <button
                            type="button"
                            className="btn btn-danger p-1 m-1"
                            style={{ maxHeight: 30, fontSize: 13 }}
                            onClick={() => handleProcessClick(timesheet)}
                            disabled={!timesheet.timesheet_total_hours && timesheet.timesheet_total_hours !== 0} // Disable the button if timesheet_total_hours is null or undefined
                          >
                            Waiting
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-success p-1 m-1"
                            style={{ maxHeight: 30, fontSize: 13 }}
                            onClick={() => handleProcessClick(timesheet)}
                            disabled={!timesheet.timesheet_total_hours && timesheet.timesheet_total_hours !== 0} // Disable the button if timesheet_total_hours is null or undefined
                          >
                            Completed
                          </button>
                        )}
                  </td>
                <td>{timesheet.timesheet_comments}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onNextPage={handleNextPage}
        onPrevPage={handlePrevPage}
      />
      {selectedTimesheet && (
        <DetailedUserView
          timesheet={selectedTimesheet}
          onUpdate={handleSaveEdit}
          onClosePopup={handleCloseEdit}
        />
      )}
    </div>
  );
};
export default TeamLeadControl;


// import React, { useState, useEffect } from 'react';

// import ApproveFormManager from './ApproveFormManager';
// import { getAllTimesheets } from './helper/coreapicalls';


// const TeamLeadControl = (props) => {
//   const [selectedTimesheet, setSelectedTimesheet] = useState(null);
//   const [timesheetsData, setTimesheetsData] = useState([]);
//   useEffect(() => {
//     const fetchData = async () => {

//       const allTimesheetsData = await getAllTimesheets();
//       console.log("testing timesheet data in manager dashboard", allTimesheetsData)
//       setTimesheetsData(allTimesheetsData.timesheetsData);
//     }
//     fetchData();
//     },
   
//  []);

//   const handleEditClick = (timesheet) => {
//     setSelectedTimesheet(timesheet);
//   };

//   const handleSaveEdit = (editedTimesheet) => {
//     setTimesheetsData((prevData) =>
//       prevData.map((timesheet) =>
        
//         timesheet.timesheet_id === editedTimesheet.timesheet_id ? editedTimesheet : timesheet
//       )
//     );
//     setSelectedTimesheet(null);
//   };
//   // const handleSaveEdit = () =>{
//   //   console.log("updating check")
//   // }

//   const handleCloseEdit = () => {
//     setSelectedTimesheet(null);
//   };

//   return (
//     <div className="row">
//       {/* <div className="col-xl-3 col-md-12 mb-4 mt-4">
//         <div className="card border-left-primary shadow h-100 py-2">
//           <div className="card-body">
//             <div className="row no-gutters align-items-center">
//               <div className="col mr-2">
//                 <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
//                   All Timesheets
//                 </div>
//                 <br />
//                 <div className="h5 mb-0 font-weight-bold text-gray-800">{timesheetsData.count}</div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div> */}
      
//       <div className="table-container" style={{ maxHeight: '500px', overflowY: 'auto' }}>
//         <table className="table table-striped">
//           <thead>
//             <tr>
//               <th>Actions</th>
//               <th>Date</th>
//               <th>Eng Name</th>
//               <th>Start Time</th>
//               <th>Sign In</th>
//               <th>Sign Out</th>
//               <th>Return</th>
//               <th>Normal Hrs</th>
//               <th>Total Hrs</th>
//               <th>OT Hrs</th>
//               <th>Expected OT</th>
//               <th>OT Amount</th>
//               <th>Holiday</th>
//               <th>Team Lead</th>
//               <th>Dept Manager</th>
//               {/* <th>Dept Head</th>
//               <th>Branch Manager</th> */}
//               <th>Final Status</th>
//               <th>Comments</th>
//             </tr>
//           </thead>
//           <tbody>
//             {timesheetsData?.map((timesheet, index) => (
//               <tr key={timesheet.timesheet_id}>
//                 <td>
//                   <button
//                     type="button"
//                     className="btn btn-outline-primary"
//                     onClick={() => handleEditClick(timesheet)}
//                   >
//                     Update
//                   </button>
//                 </td>
//                 <td>{timesheet.timesheet_date}</td>
//                 <td>{timesheet.userName}</td>
//                 <td>{timesheet.timesheet_time_start}</td>
//                 <td>{timesheet.timesheet_time_signin}</td>
//                 <td>{timesheet.timesheet_time_signout}</td>
//                 <td>{timesheet.timesheet_time_return}</td>
//                 <td>{timesheet.timesheet_normal_hours}</td>
//                 <td>{timesheet.timesheet_total_hours}</td>
//                 <td>{timesheet.timesheet_over_time_hours}</td>
//                 <td>{timesheet.timesheet_expected_amount}</td>
//                 <td>{timesheet.timesheet_overtime_amount}</td>
//                 <td>{timesheet.timesheet_is_holiday ? 'Yes' : 'No'}</td>
//                 <td>
//                   {timesheet.timesheet_teamlead_approve ? (
//                     <span className="fa-solid fa-thumbs-up badge bg-success m-2 ">
//                       &nbsp;&nbsp;Approved
//                     </span>
//                   ) : (
//                     <span className="badge bg-warning text-dark m-2 fa-solid fa-thumbs-down">
//                       &nbsp;&nbsp; Pending
//                     </span>
//                   )}
//                 </td>
//                 <td>
//                   {timesheet.timesheet_dept_manager_approve ? (
//                     <span className="fa-solid fa-thumbs-up badge bg-success m-2 ">
//                       &nbsp;&nbsp;Approved
//                     </span>
//                   ) : (
//                     <span className="badge bg-warning text-dark m-2 fa-solid fa-thumbs-down">
//                       &nbsp;&nbsp; Pending
//                     </span>
//                   )}
//                 </td>
//                 {/* <td>
//                   {timesheet.timesheet_dept_head_approve ? (
//                     <span className="fa-solid fa-thumbs-up badge bg-success m-2 ">
//                       &nbsp;&nbsp;Approved
//                     </span>
//                   ) : (
//                     <span className="badge bg-warning text-dark m-2 fa-solid fa-thumbs-down">
//                       &nbsp;&nbsp; Pending
//                     </span>
//                   )}
//                 </td> */}
//                 {/* <td>
//                   {timesheet.timesheet_branch_manager_approve ? (
//                     <span className="fa-solid fa-thumbs-up badge bg-success m-2 ">
//                       &nbsp;&nbsp;Approved
//                     </span>
//                   ) : (
//                     <span className="badge bg-warning text-dark m-2 fa-solid fa-thumbs-down">
//                       &nbsp;&nbsp; Pending
//                     </span>
//                   )}
//                 </td> */}
//                 <td>
//                   {!timesheet.timesheet_dept_manager_approve ? (
//                     <button type="button" className="btn btn-outline-danger">
//                       Waiting
//                     </button>
//                   ) : (
//                     <button type="button" className="btn btn-outline-success">
//                       Accepted
//                     </button>
//                   )}
//                 </td>
//                 <td>{timesheet.timesheet_comments}</td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//       {selectedTimesheet && (
//         <ApproveFormManager
//           timesheet={selectedTimesheet}
//           onUpdate={handleSaveEdit}
//           onClosePopup={handleCloseEdit}
          
//         />
//       )}
//     </div>
//   );
// };

// export default TeamLeadControl;


