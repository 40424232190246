import { getToken, refreshAccessToken } from "../../auth/helper";
import { API } from "../../backend";


//Get All Branches
export const getBranches = async () => {
  try {
    // Set loading state if needed
    const response = await fetch(`${API}branch/`, { method: "GET" });
    if (!response.ok) {
      // Handle non-successful HTTP status codes
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    // console.log(data);
    return data;
    // Clear loading state if needed
  } catch (error) {
    // Handle fetch or JSON parsing errors
    console.error("Error fetching branches:", error);
    throw error;
  }
};


//Get All Countries
export const getCountries = async () => {
  try {
    // Set loading state if needed
    const response = await fetch(`${API}country/`, { method: "GET" });
    if (!response.ok) {
      // Handle non-successful HTTP status codes
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    // console.log(data);
    return data;
    // Clear loading state if needed
  } catch (error) {
    // Handle fetch or JSON parsing errors
    console.error("Error fetching branches:", error);
    throw error;
  }
};




//Get All Users
export const getAllUsers = async () => {
  try {
    const Token = getToken('access');
    const url = `${API}user/`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${Token.access}`,
      },
    });
    if (!response.ok) {
      if (response.status === 401) {
        // console.log("Token expired");
        try {
          // Refresh the access token
          const newAccessToken = await refreshAccessToken(Token.refresh);
          // console.log("Check new access token:", newAccessToken);
          // Retry the API call with the new access token
          const retryResponse = await fetch(url, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${newAccessToken}`,
            },
          });
          if (!retryResponse.ok) {
            throw new Error(`HTTP Error! Status: ${retryResponse.status}`);
          }
          const retryData = await retryResponse.json();
          return retryData;
        } catch (error) {
          throw new Error(`Error refreshing access token: ${error.message}`);
        }
      } else {
        throw new Error(`HTTP Error! Status: ${response.status}`);
      }
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};






//Get All Departments
export const getDepartments = async () => {
  try {
    const response = await fetch(`${API}department/department`, { method: "GET" });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    // console.log(data);
    return data;
  } catch (error) {
    console.error("Error fetching departments:", error);
    throw error;
  }
};





// Get all Teams
export const getTeams = async () => {
  try {
    const response = await fetch(`${API}department/team`, { method: "GET" });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    // console.log(data);
    return data;
  } catch (error) {
    console.error("Error fetching teams:", error);
    throw error;
  }
};







//Update User
export const updateUser = async (userId, updatedUserData) => {
  try {
    const Token = getToken('access');

    const response = await fetch(`${API}user/${userId}/`, {
      method: "PUT", // or "PATCH" depending on your API
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${Token.access}`,
        // Include any additional headers like authorization if needed
      },
      body: JSON.stringify(updatedUserData),
    });

    if (!response.ok) {
      if (response.status === 401) {
        // console.log("Token expired");
        try {
          // Refresh the access token
          const newAccessToken = await refreshAccessToken(Token.refresh);
          // console.log("Check new access token:", newAccessToken);

          // Retry the API call with the new access token
          const retryResponse = await fetch(`${API}user/${userId}/`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${newAccessToken}`,
            },
            body: JSON.stringify(updatedUserData),
          });

          if (!retryResponse.ok) {
            throw new Error(`HTTP Error! Status: ${retryResponse.status}`);
          }

          const retryData = await retryResponse.json();
          return retryData;
        } catch (error) {
          throw new Error(`Error refreshing access token: ${error.message}`);
        }
      } else {
        throw new Error(`HTTP Error! Status: ${response.status}`);
      }
    }

    const data = await response.json();
    // console.log("User updated successfully:", data);
    return data;
  } catch (error) {
    console.error("Error updating user:", error);
    throw error;
  }
};

// Get Work by page and search job with query
export const getWork = async (page, query = '') => {

  // console.log("page and query", page, query)
  try {
    const Token = getToken('access');

    const url = `${API}work/work?page=${page}${query ? `&query=${encodeURIComponent(query)}` : ''}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${Token.access}`,
      },
    });

    if (!response.ok) {
      if (response.status === 401) {
        // console.log("token expired")
        try {
          // Refresh the access token
          const newAccessToken = await refreshAccessToken(Token.refresh);
          // console.log("check new access token:", newAccessToken)

          // Retry the API call with the new access token
          const retryResponse = await fetch(url, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${newAccessToken}`,
            },
          });

          if (!retryResponse.ok) {
            throw new Error(`HTTP Error! Status: ${retryResponse.status}`);
          }

          const retryData = await retryResponse.json();
          return retryData;
        } catch (error) {
          throw new Error(`Error refreshing access token: ${error.message}`);
        }
      } else {
        throw new Error(`HTTP Error! Status: ${response.status}`);
      }
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching work:', error);
    throw error;
  }
};


// Fetch a work using Work id
export const getWorkDetails = async (workId) => {
  try {
    const token = getToken('access');
    const response = await fetch(`${API}work/work/${workId}/`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token.access}`,
      },
    });
    // console.log("token in here", response.status)
    if (!response.ok) {
      if (response.status === 401) {
        // console.log("Token expired");
        try {
          // Refresh the access token
          const newAccessToken = await refreshAccessToken(token.refresh);
          // console.log("New access token:", newAccessToken);

          // Retry the API call with the new access token
          const retryResponse = await fetch(`${API}work/work/${workId}/`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${newAccessToken}`,
            },
          });
          if (!retryResponse.ok) {
            throw new Error(`HTTP Error! Status: ${retryResponse.status}`);
          }
          const retryData = await retryResponse.json();
          return retryData;
        } catch (error) {
          throw new Error(`Error refreshing access token: ${error.message}`);
        }
      } else {
        throw new Error(`HTTP Error! Status: ${response.status}`);
      }
    }
    const workData = await response.json();
    return workData;
  } catch (error) {
    console.error('Error fetching work details:', error);
    throw error;
  }
};


// Create new Work
export const createWork = async (newWorkData) => {
  try {
    const token = getToken('access');
    const response = await fetch(`${API}work/work/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token.access}`,
      },
      body: JSON.stringify(newWorkData),
    });

    if (!response.ok) {
      if (response.status === 401) {
        try {
          const newAccessToken = await refreshAccessToken(token.refresh);
          const retryResponse = await fetch(`${API}work/work/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${newAccessToken}`,
            },
            body: JSON.stringify(newWorkData),
          });
          if (!retryResponse.ok) {
            console.log("error occured after token refresh", retryResponse)
            throw new Error(`HTTP Error! Status: ${retryResponse.status}`);
            
          }
          const retryData = await retryResponse.json();
          return retryData;
        } catch (error) {
          console.log("HTTP Error! Status1:", response.status, response.statusText);
          console.log("Response Body1:", await response.text());
          throw new Error(`Error refreshing access token: ${error.message}`);
        }
      } else {
        // console.log("HTTP Error! Status2:", response.status, response.statusText);
        let error_Response = await response.json()
        let error_message = await error_Response.work_job_number[0]
        alert("Error: " + error_message);
        throw new Error(`HTTP Error! Status: ${response}`, response.body);
      }
    }
    const data = await response.json();
    // console.log("Work created successfully:", data);
    return data;
  } catch (error) {
    alert("Error creating work:", error)
    console.error("Error creating work:", error);
    throw error;
  }
};

// Create New Attendance from Form Data
export const createAttendance = async (newAttendanceData) => {
  // console.log("Checking data in the api calls : ", newAttendanceData)
  
  try {
    const token = getToken('access');
    const formData = new FormData();

    Object.entries(newAttendanceData).forEach(([key, value]) => {
      if (value instanceof File) {
        formData.append(key, value);
      } else {
        formData.append(key, value);
        // formData.append(key, JSON.stringify(value));
      }
    });

    const response = await fetch(`${API}work/attendance/`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token.access}`,
      },
      body: formData,
    });

    // console.log("printing response", response)

    if (!response.ok) {
      if (response.status === 401) {
        // console.log("Token expired");
        try {
          // Refresh the access token
          const newAccessToken = await refreshAccessToken(token.refresh);
          // console.log("New access token:", newAccessToken);

          // Retry the API call with the new access token
          const retryResponse = await fetch(`${API}work/attendance/`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${newAccessToken}`,
            },
            body: formData,
          });

          if (!retryResponse.ok) {
            throw new Error(`HTTP Error! Status: ${retryResponse.status}`);
          }

          const retryData = await retryResponse.json();
          return retryData;
        } catch (error) {
          throw new Error(`Error refreshing access token: ${error.message}`);
        }
      } else {
        throw new Error(`HTTP Error! Status: ${response.status}`);
      }
    }

    const data = await response.json();
    // console.log('Attendance created successfully:', data);
    return data;
  } catch (error) {
    console.error('Error creating Attendance:', error);
    throw error;
  }
};


// Update The existing work using workid
export const updateWork = async (workId, updatedWorkData) => {
  try {
    const token = getToken('access');
    const response = await fetch(`${API}work/work/${workId}/`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token.access}`,
      },
      body: JSON.stringify(updatedWorkData),
    });

    if (!response.ok) {
      if (response.status === 401) {
        // console.log("Token expired");
        try {
          // Refresh the access token
          const newAccessToken = await refreshAccessToken(token.refresh);
          // console.log("New access token:", newAccessToken);

          // Retry the API call with the new access token
          const retryResponse = await fetch(`${API}work/work/${workId}/`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${newAccessToken}`,
            },
            body: JSON.stringify(updatedWorkData),
          });

          if (!retryResponse.ok) {
            throw new Error(`HTTP Error! Status: ${retryResponse.status}`);
          }

          const retryData = await retryResponse.json();
          // console.log("Work updated successfully (after token refresh):", retryData);
          return retryData;
        } catch (error) {
          throw new Error(`Error refreshing access token: ${error.message}`);
        }
      } else {
        throw new Error(`HTTP Error! Status: ${response.status}`);
      }
    }

    const data = await response.json();
    // console.log("Work updated successfully:", data);
    return data;
  } catch (error) {
    console.error("Error updating work:", error);
    throw error;
  }
};


// Update timesheet using id 
export const updateTimesheet = async (timesheetId, newTimesheetData) => {

  // console.log("timesheet id:", timesheetId , "timesheetdata", newTimesheetData)

  try {
    const response = await fetch(`${API}timesheet/ts/${timesheetId}/`, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(newTimesheetData)
    });
    if (!response.ok) {
      const errorResponse = await response.json(); // Attempt to parse the error response
      alert("Error !!")
      throw new Error(`HTTP Error! Status: ${response.status}, ${errorResponse.error}`);
    }
    const data = await response.json();
    // console.log("Timesheet updated successfully:", data);
    return data;
  }
  catch (error) {
    console.error("Error updating timesheet: ", error);
    throw error;
  }
}
// Update timesheet using id 
export const updateTimesheetProcessData = async (timesheetId, newTimesheetData) => {

  // console.log("timesheet id:", timesheetId , "timesheetdata", newTimesheetData)

  try {
    const response = await fetch(`${API}timesheet/process/${timesheetId}/`, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(newTimesheetData)
    });
    if (!response.ok) {
      const errorResponse = await response.json(); // Attempt to parse the error response
      alert("Error !!")
      throw new Error(`HTTP Error! Status: ${response.status}, ${errorResponse.error}`);
    }
    const data = await response.json();
    // console.log("Timesheet updated successfully:", data);
    return data;
  }
  catch (error) {
    console.error("Error updating timesheet: ", error);
    throw error;
  }
}


// Get timesheet of User
export const getTimesheetofUser = async (userId, page = 1) => {
  try {
    const response = await fetch(`${API}timesheet/?timesheet_emp_id=${userId}&timesheet_save_state=0&page=${page}`, {
      method: "GET",
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const timesheetData = await response.json();

    // Check if timesheetData is an array or not
    if (!Array.isArray(timesheetData.results)) {
      // If not an array, handle the error or return an empty array
      console.error("Invalid timesheet data format:", timesheetData);
      return [];
    }
    const updatedTimesheets = await Promise.all(
      timesheetData.results.map(async (timesheet) => {

        try {
          const workDetails = await getWorkDetails(timesheet.timesheet_work_id);
          // Add work_vessel to timesheet object

          return { ...timesheet, timesheet_vessel_name: workDetails.work_vessel };
        } catch (error) {
          console.error("Error fetching work details:", error);
          throw error;
        }
      })
    );
    // Return the array of timesheets along with pagination information
    return {
      results: updatedTimesheets,
      count: timesheetData.count,
      next: timesheetData.next,
      previous: timesheetData.previous,
    };
  } catch (error) {
    console.error("Error fetching timesheet data:", error);
    throw error;
  }
};



// Get all timesheets by sending page and fetch only a user's timesheet if the parameter "userId" is present
export const getAllTimesheets = async (page = 1, userId = null, msid= null) => {

  // console.log("test msid", msid);
  try {
    let url = `${API}timesheet/ts/?page=${page}`;
    if (userId !== null) {
      url += `&timesheet_emp_id=${userId}`;
    }
    if (msid !== null){
      url += `&msid=${msid}`;
    }
    const response = await fetch(url, {
      method: "GET"
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const { count, next, previous, results } = await response.json();


    
    return { count, next, previous, timesheetsData: results };

  } catch (error) {
    console.error("Error fetching All Timesheets:", error);
    throw error;
  }
}



//Fetch All timesheets of A Team by passing TeamID and page for pagination 
export const getAllTimesheetsByTeamId = async (teamId = null, page, msid = null,startdate = null, enddate = null, Job_No = null) => {

  // console.log("WORKINGGG")

  
  try {
    let url = `${API}timesheet/ts/?page=${page}`
    if (teamId !== null) {
      url +=  `&timesheet_emp_team_id=${teamId}`;
    }
    if (msid !== null){
      url += `&msid=${msid}`;
    }
    if (Job_No !== null){
      url += `&work_job_number=${Job_No}`;
    }
    if (startdate !== null && enddate !== null){
      url += `&start_date=${startdate}&end_date=${enddate}`;
    }

    const response = await fetch(url, {
      method: "GET"
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
  const { count, next, previous, results } = await response.json();
  // console.log("response result isSSSSSSSSSSSSSSS", results);
    return { count, next, previous, timesheetsData: results };


    // const response = await fetch(`${API}timesheet/ts/?page=${page}`, {
    //   method: "GET",
    // });
    // if (!response.ok) {
    //   throw new Error(`HTTP error! Status: ${response.status}`);
    // }
    // const { count, next, previous, results } = await response.json();
    // return { count, next, previous, timesheetsData: results };
  } catch (error) {
    console.error("Error fetching paginated timesheets:", error);
    throw error;
  }
};


// Get user with all quota and other details (nested api. so use it for the must needed case only)
// Get username using user id
export const getUserDetailsNested = async (userId) => {
  let url
  try {
    if(userId !== null && userId!== undefined){
      url = `${API}user/employee_details/${userId}`
    }
    else{
      url= `${API}user/employee_details/`
    }
    const token = getToken('access');
    const response = await fetch(url, {
      method: "GET",
      headers: {
        'Authorization': `Bearer ${token.access}`,
      },
    });
    if (!response.ok) {
      if (response.status === 401) {
        // console.log("Token expired");
        try {
          // Refresh the access token
          const newAccessToken = await refreshAccessToken(token.refresh);
          // console.log("New access token:", newAccessToken);

          // Retry the API call with the new access token
          const retryResponse = await fetch(url, {
            method: "GET",
            headers: {
              'Authorization': `Bearer ${newAccessToken}`,
            },
          });

          if (!retryResponse.ok) {
            throw new Error(`HTTP error! Status: ${retryResponse.status}`);
          }

          const user = await retryResponse.json();
          const empData = user;
          console.log(`User details fetched successfully (after token refresh) for ID ${userId}:`);
          return empData;
        } catch (error) {
          throw new Error(`Error refreshing access token: ${error.message}`);
        }
      } else {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    }

    const user = await response.json();
    const empData = user;
    // console.log(`User details fetched successfully for ID ${userId}:`, user);
    return empData;
  } catch (error) {
    console.error(`Error fetching user details for ID ${userId}:`, error);
    throw error;
  }
};


// Create new user quota or update existing one
export const createUserQuota = async (userId, userRole) => {
  try {
    const token = getToken('access');
    // Check if the user already has an employee quota
    const existingEmployeeQuotaResponse = await fetch(`${API}user/employee_quota/?emp_quota_emp=${userId}`, {
      method: "GET",
      headers: {
        'Authorization': `Bearer ${token.access}`,
        'Content-Type': 'application/json',
        // Add any additional headers as needed
      },
    });
    if (!existingEmployeeQuotaResponse.ok) {
      if (existingEmployeeQuotaResponse.status === 401) {
        // console.log("Token expired");
        try {
          // Refresh the access token
          const newAccessToken = await refreshAccessToken(token.refresh);
          // Retry the API call with the new access token
          const retryResponse = await fetch(`${API}user/employee_quota/?emp_quota_emp=${userId}`, {
            method: "GET",
            headers: {
              'Authorization': `Bearer ${newAccessToken}`,
              'Content-Type': 'application/json',
              // Add any additional headers as needed
            },
          });
          if (!retryResponse.ok) {
            throw new Error(`HTTP error! Status: ${retryResponse.status}`);
          }
          const existingEmployeeQuota = await retryResponse.json();
          if (existingEmployeeQuota.length > 0) {
            const currentEmpQuota = existingEmployeeQuota[0];
            // If it exists, update it
            const updateResponse = await fetch(`${API}user/employee_quota/${currentEmpQuota.emp_quota_id}/`, {
              method: "PUT",
              headers: {
                'Authorization': `Bearer ${newAccessToken}`,
                'Content-Type': 'application/json',
                // Add any additional headers as needed
              },
              body: JSON.stringify({
                user_id: userId,
                user_role: userRole,
              }),
            });
            if (!updateResponse.ok) {
              throw new Error(`HTTP error! Status: ${updateResponse.status}`);
            }
            const updatedUserRole = await updateResponse.json();
            return updatedUserRole;
          }
        } catch (error) {
          throw new Error(`Error refreshing access token: ${error.message}`);
        }
      } else {
        throw new Error(`HTTP error! Status: ${existingEmployeeQuotaResponse.status}`);
      }
    }
    const existingEmployeeQuota = await existingEmployeeQuotaResponse.json();
    if (existingEmployeeQuota.length > 0) {
      const currentEmpQuota = existingEmployeeQuota[0];
      // If it exists, update it
      const updateResponse = await fetch(`${API}user/employee_quota/${currentEmpQuota.emp_quota_id}/`, {
        method: "PUT",
        headers: {
          'Authorization': `Bearer ${token.access}`,
          'Content-Type': 'application/json',
          // Add any additional headers as needed
        },
        body: JSON.stringify({
          user_id: userId,
          user_role: userRole,
        }),
      });

      if (!updateResponse.ok) {
        throw new Error(`HTTP error! Status: ${updateResponse.status}`);
      }

      const updatedUserRole = await updateResponse.json();
      return updatedUserRole;
    } else {
      // If it doesn't exist, create a new one
      const createResponse = await fetch(`${API}user/employee_quota/`, {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${token.access}`,
          'Content-Type': 'application/json',
          // Add any additional headers as needed
        },
        body: JSON.stringify({
          user_role: userRole,
          user_id: userId,
        }),
      });

      if (!createResponse.ok) {
        throw new Error(`HTTP error! Status: ${createResponse.status}`);
      }

      const createdUserRole = await createResponse.json();
      return createdUserRole;
    }
  } catch (error) {
    console.error("Error creating/updating user role:", error);
    throw error;
  }
};


