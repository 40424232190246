import React, { useState } from "react";
import { Navigate, Link } from "react-router-dom";
import Base from "../core/Base";
import {
  authenticate,
  isAuthenticated,
  signin,
  userRole,
} from "../auth/helper";

const Signin = () => {
  const [values, setValues] = useState({
    emp_email: "",
    password: "",
    error: "",
    success: false,
    loading: false,
    didRedirect: false,
  });

  const { emp_email, password, loading } = values;

  const handleChange = (name) => (event) => {
    setValues({ ...values, error: false, [name]: event.target.value });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setValues({ ...values, error: false, loading: true });

    signin({ emp_email, password })
      .then((data) => {
        // console.log("data in signin",data.access)
        if (data.access) {
          alert("Login success");
          authenticate(data, () => {
            // console.log('TokenAdded');
            setValues({
              ...values,
              didRedirect: true,
            });
          });
        } else {
          setValues({
            ...values,
            loading: false,
            error: data.error || "Something went wrong!",
          });
        }
      })
      .catch((e) => {
        setValues({
          ...values,
          loading: false,
          error: "Something went wrong!",
        });
        console.log("Error:");
      });

    // .catch((e) =>  console.log("Error : ", e ) );
  };

  const performRedirect = () => {
    // console.log("check authentication", isAuthenticated())
    if (isAuthenticated() && values.didRedirect) {
      const userRoles = userRole();
      // console.log("user roles in signin page", userRoles)
      const redirectMap = {
        is_superuser: "/user/dashboard-gm/",
        emp_is_gm: "/dashboard-gm",
        emp_is_deptmanager: "/user/dashboard-deptmanager",
        emp_is_srsvengg: "/user/dashboard",
        emp_is_admin: "/user/dashboard-admin",
        emp_is_branchmanager: "/dashboard-branchmanager",
        emp_is_depthead: "/dashboard-depthead",
        emp_is_driver: "/dashboard-driver",
        emp_is_hrmanager: "/dashboard-hrmanager",
        emp_is_jrsvengg: "/user/dashboard",
        emp_is_operation: "/user/dashboard-operation",
        emp_is_svengg: "/user/dashboard",
        default: "/user/dashboard/default",
      };

      const redirectPath =
        userRoles.find((role) => redirectMap[role]) || "default";
      return <Navigate to={redirectMap[redirectPath]} />;
    }
  };

  const loadingMessage = () => {
    return (
      loading && (
        <div className="overlay">
          <div className="d-flex justify-content-center align-items-center vh-100">
            <div className="spinner-border text-danger" role="status"></div>
          </div>
        </div>
      )
    );
  };

  const signintest = () => {
    return (
      <div className="row">
        <div className="col-md-6 offset-sm-3 text-left pb-3 pt-2 pl-5 pr-5 ">
          <form>
            <div className="box">
              <div>
                <div>
                  <label htmlFor="floatingInput">Email address</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    className="form-control input-box"
                    value={emp_email}
                    onChange={handleChange("emp_email")}
                    type="text"
                  />
                </div>
              </div>
              <div>
                <div>
                  <label htmlFor="floatingPassword">Password</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    className="form-control input-box"
                    value={password}
                    onChange={handleChange("password")}
                    type="password"
                  />
                </div>
              </div>
              <div className="form-group ">
                <button
                  onClick={onSubmit}
                  className="btn1 btn-success btn-block"
                >
                  Sign in
                </button>
              </div>
              <br></br>
              <div className="text-center" style={{ color: "black" }}>
                New user? <Link to="/signup"> Signup</Link> now!
              </div>
            </div>
            <br></br>
          </form>
        </div>
      </div>
    );
  };

  return (
    <Base
      title="Maritech Solutions"
      description=" Sign-in"
      mobileBgImage=""
      desktopBgImage=""
    >
      {loadingMessage()}
      {signintest()}
      {performRedirect()}
    </Base>
  );
};

export default Signin;
