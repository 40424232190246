// import React, { useState } from 'react';

// const TimesheetSearch = ({ initialConfig, onSearch }) => {
//   const [filters, setFilters] = useState({});

//   const handleInputChange = (event) => {
//     const { name, value } = event.target;
//     setFilters({ ...filters, [name]: value });
//   };

//   const handleTeamChange = (selectedTeamId) => {
//     setFilters({ ...filters, teamId: selectedTeamId });
//   };

//   const handleHasOTChange = (event) => {
//     setFilters({ ...filters, hasOT: event.target.checked });
//   };

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     onSearch(filters); // Call onSearch with current filters
//   };

//   const handleClearFilter = ()=>{
//     setFilters({});
//     // onSearch({});

//   }

//   const renderFilter = (filter) => {

//     switch (filter.type) {
//       case 'text':
//         return (

//           <div >
//             <div key={filter.name}>
//               <label htmlFor={filter.name}>{filter.label}:</label>
//               {/* <label>MSID:</label> */}
//               <input
//                 type="text"
//                 id={filter.name}
//                 name={filter.name}
//                 value={filters[filter.name] || ''}
//                 onChange={handleInputChange}
//               />
//             </div>
//           </div>
//         );
//       case 'select':
//         return (
//           <div key={filter.name}>
//             {/* <label htmlFor={filter.name}>{filter.label}:</label> */}
//             {/* Replace with your existing team dropdown component */}
//             <select id={filter.name} name={filter.name} onChange={(e) => handleTeamChange(e.target.value)}>
//               <option value="">Select {filter.label}</option>
//               {/* Populate options based on your team data */}
//             </select>
//           </div>
//         );
//       case 'date':
//         return (
//           <div key={filter.name}>
//             <label htmlFor={filter.name}>{filter.label}:</label>
//             <input
//               type="date"
//               id={filter.name}
//               name={filter.name}
//               value={filters[filter.name] || ''}
//               onChange={handleInputChange}
//             />
//           </div>
//         );
//       case 'checkbox':
//         return (
//           <div key={filter.name}>
//             <label>
//               <input
//                 type="checkbox"
//                 id={filter.name}
//                 name={filter.name}
//                 checked={filters[filter.name] || false}
//                 onChange={handleHasOTChange}
//               />
//               {filter.label}
//             </label>
//           </div>
//         );
//       default:
//         return null;
//     }
//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       <div className="row">
//         <div className="col"> {initialConfig.map((filter) => renderFilter(filter))} </div>
//         <div className="col "> <button type="submit">Search</button> </div>
//         <div className="col "> <button onClick={handleClearFilter}>Clear</button> </div>
//       </div>
//     </form>
//   );
// };

// export default TimesheetSearch;

import React, { useState } from "react";

const TimesheetSearch = ({ initialConfig, onSearch }) => {
  const [filters, setFilters] = useState({});

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFilters({ ...filters, [name]: value });
  };

  const handleTeamChange = (selectedTeamId) => {
    setFilters({ ...filters, teamId: selectedTeamId });
  };

  const handleHasOTChange = (event) => {
    setFilters({ ...filters, hasOT: event.target.checked });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSearch(filters); // Call onSearch with current filters
  };

  const handleClearFilter = () => {
    setFilters({});
    onSearch({});
  };

  const renderFilter = (filter) => {
    switch (filter.type) {
      case "text":
        return (
          <div key={filter.name} className="mb-3 col">
            <label htmlFor={filter.name} className="form-label">
              {filter.label}:
            </label>
            <input
              type="text"
              className="form-control form-control-sm" // Add form-control-sm class here
              id={filter.name}
              name={filter.name}
              value={filters[filter.name] || ""}
              onChange={handleInputChange}
            />
          </div>

        );
      case "select":
        return (
          <div key={filter.name} className="mb-3 col">
            <label htmlFor={filter.name} className="form-label">
              {filter.label}:
            </label>
            <select
              id={filter.name}
              name={filter.name}
              className="form-control  form-control-sm"
              onChange={(e) => handleTeamChange(e.target.value)}
            >
              <option value="">Select {filter.label}</option>
              {/* Populate options based on your team data */}
            </select>
          </div>
        );
      case "date":
        return (
          <div key={filter.name} className="mb-3 col">
            <label htmlFor={filter.name} className="form-label ">
              {filter.label}:
            </label>
            <input
              type="date"
              className="form-control  form-control-sm"
              id={filter.name}
              name={filter.name}
              value={filters[filter.name] || ""}
              onChange={handleInputChange}
            />
          </div>
        );
      case "checkbox":
        return (
          <div key={filter.name} className="mb-3 col">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id={filter.name}
                name={filter.name}
                checked={filters[filter.name] || false}
                onChange={handleHasOTChange}
              />
              <label className="form-check-label" htmlFor={filter.name}>
                {filter.label}
              </label>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row" style={{ maxWidth: 600 }}>
        {initialConfig.map((filter) => renderFilter(filter))}
        <div>
          {" "}
          <button type="submit" className="btn btn-primary me-2">
            Search
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleClearFilter}
          >
            Clear
          </button>
        </div>
        <div className="col d-flex align-items-end"></div>
      </div>
    </form>
  );
};

export default TimesheetSearch;
