import React from 'react';
import Menu from './Menu';
import Footer from './Footer';

const Base = ({
  box_Shadow,
  title = 'My Title',
  description = 'My description',
  className = 'text-black',
  mobileBgImage = '/landing.webp', // Update the path accordingly
  desktopBgImage = '/images/desktop-image.jpg', // Update the path accordingly
  dynamicColor = '#858686',
  children,
}) => {
  return (
    <div className="d-flex flex-column" style={{ minHeight: '100vh' }}>
      <Menu />
      <div className="flex-grow-1" 
      style={{ backgroundImage: `url('${mobileBgImage}')`,
      maxHeight:'150vh',
       backgroundSize: 'cover', 
       backgroundPosition: 'center', 
       backgroundRepeat: 'no-repeat', 
       width: '100%',


       }}>
        <div className="container-fluid">
          <div className="jumbotron bg-transparent text-white text-center mb-n3 pb-2 pt-">
            <h4 style={{ color: dynamicColor }}>{title}</h4>
            <p className="lead" style={{ color: dynamicColor }}>{description}</p>
          </div>
          <div className={`container ${className}`}>
            {children}
          </div>
        </div>
      </div>
      <Footer boxShadow={ box_Shadow }/>
    </div>
  );
};

export default Base;
