import React, { useEffect, useState } from "react";
import { getWork } from "../core/helper/coreapicalls";
import SearchUser from "../core/SearchUser";
import { useNavigate } from "react-router-dom";
import Pagination from "../core/Pagination";

const ITEMS_PER_PAGE = 100; // Adjust as needed

const WorkListUser = () => {
    const navigate = useNavigate();
    const [jobs, setJobs] = useState([]);

    const [error, setError] = useState(null);
    const [sortOrder, setSortOrder] = useState("asc");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [searchQuery, setSearchQuery] = useState("");
    const [loading, setLoading] = useState(false);

    const loadingMessage = () => {
        return (
            loading && (
                <div className="overlay">
                    <div className="d-flex justify-content-center align-items-center vh-100">
                        <div className="spinner-border text-danger" role="status"></div>
                    </div>
                </div>
            )
        );
    };

    useEffect(() => {
        const fetchJobs = async () => {
            try {
                setLoading(true);
                // const response = await getWork(currentPage);
                const response = await getWork(currentPage, searchQuery);
                const data = Array.isArray(response.results) ? response.results : [];
                setJobs(data);
                setTotalPages(Math.ceil(response.count / ITEMS_PER_PAGE));
                setLoading(false);
            } catch (error) {
                setLoading(false);
                setError(error.message || "An error occurred");
            } finally {
                setLoading(false);
            }
        };
        fetchJobs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    const handleSort = () => {
        const sortedJobs = [...jobs].sort((a, b) => {
            const dateA = new Date(a.updated_at);
            const dateB = new Date(b.updated_at);
            if (sortOrder === "asc") {
                return dateA - dateB;
            } else {
                return dateB - dateA;
            }
        });
        setJobs(sortedJobs);
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    };
    const handleSearch = async (searchTerm) => {
        setSearchQuery(searchTerm);
        try {
            setLoading(true);
            // Reset currentPage to 1 when a new search is initiated
            setCurrentPage(1);
            const response = await getWork(1, searchTerm);
            // Handle empty search results gracefully
            if (response.count === 0) {
                setLoading(false);
                alert("No results found for your search.");
                return;
            }
            // Normalize the response structure if needed (assuming 'results' exists)
            const normalizedResults = Array.isArray(response)
                ? response
                : response.results;
            setJobs(normalizedResults);
            setTotalPages(Math.ceil(response.count / ITEMS_PER_PAGE));
            setLoading(false);

            // setTotalPages(response.count / ITEMS_PER_PAGE);
        } catch (error) {
            setLoading(false);
            alert("Error Searching Works!!");
            console.error("Error searching works:", error);
        }
    };

    const handleAttend = (workId) => {
        // console.log("this is the work is passing:", workId)
        // Navigate to the new page with the corresponding work's ID
        navigate(`/user/create-attendance/${workId}`);
    };

    const handleNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };

    const handlePrevPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    return (
        <div>
            <div className="row ">
                <div className="col-6 ">
                    <SearchUser onSearch={handleSearch} SearchFor="Search Vessel" />
                </div>
                <div className="col-4 ">
                    <button className="btn   " onClick={handleSort}>
                        {sortOrder === "asc" ? "Sort ⬆️" : "Sort ⬇️"}
                    </button>
                </div>
            </div>

            {loading && loadingMessage()}
            {error && <p>Error: {error}</p>}
            {jobs.length > 0 && (
                <>
                    <div
                        className="table-container"
                        style={{ maxHeight: "500px", overflowY: "auto" }}
                    >
                        <table className="table table-striped">
                            <thead
                                style={{
                                    position: "sticky",
                                    top: 0,
                                    zIndex: 1,
                                    background: "yellow ",
                                }}
                            >
                                <tr>

                                    <th>Sl.No</th>
                                    <th>Vessel Name</th>
                                    <th>Job No:</th>
                                    <th>PIC</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>

                                {jobs.map((job, index) => (
                                    <tr key={job.work_id}>
                                        <th scope="row">{index + 1}</th>
                                        <td>{job.work_vessel}</td>
                                        <td>{job.work_job_number}</td>
                                        <td>{job.work_job_pic_name}</td>
                                        <td>
                                            <button
                                                className="btn btn-primary badge"
                                                onClick={() => handleAttend(job.work_id)}
                                            >
                                                Attend
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    {/* Use the Pagination component */}
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onNextPage={handleNextPage}
                        onPrevPage={handlePrevPage}
                    />
                </>
            )}
        </div>
    );
};

export default WorkListUser;
