import React, { useEffect, useState } from "react";
import { createUserQuota, updateUser } from "./helper/coreapicalls";
import CountryDropdown from "../user/CountryDropdown";
import BranchDropdown from "../user/BranchDropdown";
import TeamDropdown from "../user/TeamDropdown";
import DepartmentDropdown from "../user/DepartmentDropdown";

const AllUsersList1 = ({ userList, updateUserList }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [checkboxValues, setCheckboxValues] = useState({});
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);

  useEffect(() => {}, []); // Empty dependency array ensures useEffect runs once on mount

  const checkboxLabels = {
    is_active: "User Status",
    emp_is_gm: "General Manager",
    emp_is_depthead: "Department Head",
    emp_is_branchmanager: "Branch Manager",
    emp_is_deptmanager: "Department Manager",
    emp_is_jrsvengg: "Jr.Service Eng.",
    emp_is_svengg: "Service Eng.",
    emp_is_srsvengg: "Sr.Service Eng.",
    emp_is_driver: "Driver",
    emp_is_operation: "Operations Staff",
    emp_is_admin: "Administration Staff",
    emp_is_hrmanager: "HR Manager",
    // Add more labels as needed
  };

  const handlePopupToggle = (user) => {
    setSelectedUser(user);
    setIsPopupOpen(!isPopupOpen);
    const initialCheckboxValues = {};
    Object.keys(user).forEach((key) => {
      if (
        key !== "id" &&
        key !== "emp_msid" &&
        key !== "emp_name" &&
        key !== "emp_email" &&
        key !== "emp_phone" &&
        key !== "active" &&
        key !== "is_staff" &&
        key !== "is_superuser" &&
        key !== "emp_gender" &&
        key !== "emp_branch_id" &&
        key !== "emp_country_id" &&
        key !== "emp_dept_id" &&
        key !== "emp_team_id"
      ) {
        initialCheckboxValues[key] = !!user[key]; // Convert truthy/falsy values to booleans
      }
    });
    setCheckboxValues(initialCheckboxValues);

    setSelectedCountry({
      country_id: user.emp_country_id,
    });

    setSelectedBranch({
      branch_id: user.emp_branch_id,
    });

    setSelectedDepartment({
      department_id: user.emp_dept_id,
    });

    setSelectedTeam({
      team_id: user.emp_team_id,
    });
  };

  const handleCheckboxChange = (key) => {
    setCheckboxValues((prevValues) => {
      let updatedValues = { ...prevValues, [key]: !prevValues[key] };
      // Logic for engineer checkboxes
      if (
        key === "emp_is_jrsvengg" ||
        key === "emp_is_svengg" ||
        key === "emp_is_srsvengg"
      ) {
        // Ensure only one of the engineer conditions can be selected at a time
        if (updatedValues["emp_is_jrsvengg"]) {
          updatedValues["emp_is_svengg"] = false;
          updatedValues["emp_is_srsvengg"] = false;
        } else if (updatedValues["emp_is_svengg"]) {
          updatedValues["emp_is_jrsvengg"] = false;
          updatedValues["emp_is_srsvengg"] = false;
        } else if (updatedValues["emp_is_srsvengg"]) {
          updatedValues["emp_is_jrsvengg"] = false;
          updatedValues["emp_is_svengg"] = false;
        }
      } else if (key === "emp_is_driver") {
        // Disable the driver checkbox if none of the engineer conditions is selected
        if (
          !updatedValues["emp_is_jrsvengg"] &&
          !updatedValues["emp_is_svengg"] &&
          !updatedValues["emp_is_srsvengg"]
        ) 
        {
          updatedValues["emp_is_driver"] = false;
        }
      }
      // Uncheck the driver checkbox if none of the engineer conditions is selected
      if (
        !updatedValues["emp_is_jrsvengg"] &&
        !updatedValues["emp_is_svengg"] &&
        !updatedValues["emp_is_srsvengg"]
      ) {
        updatedValues["emp_is_driver"] = false;
      }
      return updatedValues;
    });
  };

  const handleUpdateUser = async () => {
    try {
      const updatedUserData = {
        ...selectedUser,
        ...checkboxValues,
        emp_country_id: selectedCountry ? selectedCountry.country_id : null,
        emp_branch_id: selectedBranch ? selectedBranch.branch_id : null,
        emp_dept_id: selectedDepartment
          ? selectedDepartment.department_id
          : null,
        emp_team_id: selectedTeam ? selectedTeam.team_id : null,
      };
      // Assuming updateUser is a function that updates the user on the server
      const updatedUser = await updateUser(selectedUser.id, updatedUserData);
      // Assuming updateUserList is a function passed as a prop to update the user list in the parent component
      if (updateUser) {
        updateUserList((prevUserList) =>
          prevUserList.map((user) =>
            user.id === updatedUser.id ? updatedUser : user
          )
        );
        alert("user updated successfully!");
      }
      // Make API call if certain checkboxes are checked
      if (
        checkboxValues["emp_is_jrsvengg"] ||
        checkboxValues["emp_is_svengg"] ||
        checkboxValues["emp_is_srsvengg"]
      ) {
        const userRole = checkboxValues["emp_is_jrsvengg"]
          ? "emp_is_jrsvengg"
          : checkboxValues["emp_is_svengg"]
          ? "emp_is_svengg"
          : "emp_is_srsvengg";
        try {
          const response = await createUserQuota(selectedUser.id, userRole);
          if (response) {
            console.log("quota updated");
          } else {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
        } catch (error) {
          console.error("Error making API call:", error.message);
        }
      }
    } catch (error) {
      console.error("Error updating user:", error);
    } finally {
      setIsPopupOpen(false);
    }
  };
  return (
    <div
      className="table-container"
      style={{ maxHeight: "700px", overflowY: "auto" }}
    >
      <table className="table table-striped">
        <thead style={{ position: "sticky", top: 0, zIndex: 1 }}>
          <tr
            style={{ alignContent: "center", textAlign: "start", fontSize: 13 }}
          >
            <th scope="col">Sl.No:</th>
            <th scope="col">Edit User</th>
            <th scope="col">MSID</th>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th scope="col">Phone</th>
            <th scope="col">Activation Status</th>
          </tr>
        </thead>
        <tbody>
          {userList.map((user, index) => (
            <tr
              key={index}
              style={{
                alignContent: "center",
                textAlign: "start",
                fontSize: 13,
                maxWidth: 10,
              }}
            >
              <th scope="row">{index + 1}</th>
              <td>
                <button
                  type="button"
                  className="btn fa fa-edit"
                  style={{ color: "blue", fontSize: 20 }}
                  onClick={() => handlePopupToggle(user)}
                >
                  {" "}
                </button>
              </td>
              <td>{user.emp_msid}</td>
              <td>{user.emp_name}</td>
              <td>{user.emp_email}</td>
              <td>{user.emp_phone}</td>
              {/* <td>{user.emp_gender}</td> */}
              {/* <td>{user.emp_country_id}</td>
                            <td>{user.emp_branch_id}</td>
                            <td>{user.emp_dept_id}</td>
                            <td>{user.emp_team_id}</td> */}
              <td>
                {!user.is_active ? (
                  <span className="badge bg-warning text-dark m-2 fa-solid fa-user-large-slash">
                    {" "}
                    &nbsp;&nbsp;Inactive
                  </span>
                ) : 
                (
                  <span className="fa-regular fa-user badge bg-success m-2">
                    &nbsp;&nbsp; Active
                  </span>
                )
                }
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {isPopupOpen && selectedUser && (
        <div className="popup popup-larger">
          <div className="popup-content popup-content-larger">
            <form>
              <div className="row">
                <div className="col-xl-12 col-md-2 mb-2">
                  <div className="card border-left-primary shadow h-100 ">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                            User Name:
                          </div>
                          <div className="h6 mb-0 font-weight-bold text-gray-800">
                            {selectedUser.emp_name}
                          </div>
                        </div>
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                            MSID:
                          </div>
                          <div className="h6 mb-0 font-weight-bold text-gray-800">
                            {selectedUser.emp_msid}
                          </div>
                        </div>
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                            Email
                          </div>
                          <div className="h6 mb-0 font-weight-bold text-gray-800">
                            {selectedUser.emp_email}
                          </div>
                        </div>
                        <div className="col-auto">
                          <i className="fas fa-user fa-2x text-gray-300"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {Object.keys(selectedUser).map((key) => {
                    
                  if (
                    key === "emp_branch_id" ||
                    key === "emp_country_id" ||
                    key === "emp_dept_id" ||
                    key === "emp_team_id" ||
                    key === "emp_name" ||
                    key === "id" ||
                    key === "emp_email" ||
                    key === "emp_phone" ||
                    key === "emp_gender" ||
                    key === "is_staff" ||
                    key === "is_superuser" ||
                    key === "emp_msid" ||
                    key === "updated_at"
                  ) {
                    return null;
                  }
                  return (
                    <div key={key} className="col-xl-3 col-md-6 mb-1">
                      <div className="card border-left-primary shadow h-100 py-2">
                        <div className="card-body">
                          <div
                            key={key}
                            className="col-md-4 mb-3 custom-checkbox-container"
                          >
                            <label
                              htmlFor={`checkbox-${key}`}
                              className="checkbox-label"
                            >
                              {checkboxLabels[key] || key}
                            </label>
                            <div className="checkbox-wrapper">
                              <input
                                type="checkbox"
                                id={`checkbox-${key}`}
                                checked={checkboxValues[key]}
                                onChange={() => handleCheckboxChange(key)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div className="container">
                  <br></br>
                  <div className="row">
                    <div className="col ">
                      <label
                        htmlFor="countryDropdown"
                        style={{ color: "black" }}
                      >
                        Country
                      </label>
                      <CountryDropdown
                        onSelectCountry={setSelectedCountry}
                        defaultValue={selectedCountry}
                      />
                    </div>
                    <div className="col">
                      <label
                        htmlFor="branchDropdown"
                        style={{ color: "black" }}
                      >
                        Branch
                      </label>
                      <BranchDropdown
                        onSelectBranch={setSelectedBranch}
                        defaultValue={selectedBranch}
                      />
                    </div>
                    <div className="col">
                      <label
                        htmlFor="departmentDropdown"
                        style={{ color: "black" }}
                      >
                        Department
                      </label>
                      <DepartmentDropdown
                        onSelectDepartment={setSelectedDepartment}
                        defaultValue={selectedDepartment}
                      />
                    </div>

                    <div className="col">
                      <label htmlFor="teamDropdown" style={{ color: "black" }}>
                        Team
                      </label>
                      <TeamDropdown
                        onSelectTeam={setSelectedTeam}
                        defaultValue={selectedTeam}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="button"
                className="btn btn-primary m-3"
                onClick={handleUpdateUser}
              >
                Update
              </button>
              <button
                type="button"
                className="btn btn-primary m-3"
                onClick={() => setIsPopupOpen(false)}
              >
                Close
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllUsersList1;
