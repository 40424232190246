// DepartmentDropdown.js
import React, { useEffect, useState } from "react";
import { getDepartments } from '../core/helper/coreapicalls';

const DepartmentDropdown = ({ onSelectDepartment, defaultValue }) => {
    const [departmentData, setDepartmentData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getDepartments();
                setDepartmentData(data);
            } catch (error) {
                setError(error.message || "An error occurred");
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const handleDepartmentChange = (event) => {
        const selectedDepartmentId = event.target.value;
        const department = departmentData.find((d) => d.department_id === parseInt(selectedDepartmentId));
        onSelectDepartment(department);
    };

    return (
        <div style={{minWidth:229}}>
            {loading && <p>Loading...</p>}
            {error && <p>Error: {error}</p>}
            {departmentData.length > 0 && (
                // <select onChange={handleDepartmentChange} value={defaultValue ? defaultValue.department_id || '' : ''} required>
                <div>                <select className="form-control input-box" onChange={handleDepartmentChange} value={defaultValue && typeof defaultValue === 'object' ? defaultValue.department_id || '' : defaultValue || ''} required>

                    <option value="" disabled>Select a Department</option>
                    {departmentData.map((department) => (
                        <option key={department.department_id} value={department.department_id}>
                            {department.department_name}
                        </option>
                    ))}
                </select>
                </div>
            )}
        </div>
    );
};

export default DepartmentDropdown;
