
import React, { useEffect, useState } from 'react';
import { updateTimesheet } from '../core/helper/coreapicalls';

const ApproveTimesheetForm = ({ timesheet, onUpdate, onClosePopup, onUpdateTimesheet }) => {
  const [formData, setFormData] = useState({
    timesheet_time_start: '',
    timesheet_time_signin: '',
    timesheet_time_signout: '',
    timesheet_time_return: '',
    timesheet_comments:'',
    timesheet_expected_amount: 0,
    timesheet_is_holiday: false,
    timesheet_overtime_amount: 0,
    timesheet_teamlead_approve: false,
    isRejected: false,
  });

  useEffect(() => {
    setFormData({
      timesheet_time_start: timesheet.timesheet_time_start || '',
      timesheet_time_signin: timesheet.timesheet_time_signin || '',
      timesheet_time_signout: timesheet.timesheet_time_signout || '',
      timesheet_time_return: timesheet.timesheet_time_return || '',
      timesheet_comments:timesheet.timesheet_comments || '',
      timesheet_expected_amount: timesheet.timesheet_expected_amount || 0,
      timesheet_is_holiday: timesheet.timesheet_is_holiday || false,
      timesheet_overtime_amount: timesheet.timesheet_overtime_amount || 0,
      timesheet_teamlead_approve: timesheet.timesheet_teamlead_approve || false,
      isRejected: timesheet.timesheet_is_reject || false,
    });
  }, [timesheet]);
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const updatedValue = type === 'checkbox' ? checked : value;
  
    const formattedTime = value ? `${value}:00` : null;
  
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'time' ? formattedTime : updatedValue,
    }));
  
    if (name === 'timesheet_teamlead_approve' && value === 'rejected') {
      setFormData((prevData) => ({
        ...prevData,
        isRejected: true,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        isRejected: false,
      }));
    }
  
    // console.log('Form Data:', formData); // Log the form data for debugging
  };
  
//   const handleChange = (e) => {
//     const { name, value, type, checked } = e.target;
//     const updatedValue = type === 'checkbox' ? checked : value;

//     const formattedTime = value ? `${value}:00` : null;

//     setFormData((prevData) => ({
//         ...prevData,
//         [name]: type === 'time' ? formattedTime : updatedValue,
//     }));

//     if (name === 'timesheet_teamlead_approve' && value === 'rejected') {
//         setFormData((prevData) => ({
//             ...prevData,
//             isRejected: true,
//         }));
//     } else {
//         setFormData((prevData) => ({
//             ...prevData,
//             isRejected: false,
//         }));
//     }
// };


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !formData.timesheet_time_start ||
      !formData.timesheet_time_signin ||
      !formData.timesheet_time_signout ||
      !formData.timesheet_time_return
    ) {
      alert('Please fill in all time fields.');
      return;
    }

    try {

      // onUpdateTimesheet(updatedTimesheet);

      const confirmationMessage = `You entered the following times:\n
        Time Start: ${formData.timesheet_time_start}\n
        Time Sign In: ${formData.timesheet_time_signin}\n
        Time Sign Out: ${formData.timesheet_time_signout}\n
        Time Return: ${formData.timesheet_time_return}\n
        Expected Amount: ${formData.timesheet_expected_amount}\n
        Comments: ${formData.timesheet_comments}\n
        Is Holiday: ${formData.timesheet_is_holiday ? 'Yes' : 'No'}\n
        Overtime Amount: ${formData.timesheet_overtime_amount}\n
        Team Lead Approval: ${formData.timesheet_teamlead_approve ? 'Approved' : 'Pending'}`;

      if (window.confirm(confirmationMessage)) {
        console.log("form dataaaaaaaaaaaaaaa",formData )

        const updatedTimesheet = await updateTimesheet(timesheet.timesheet_id, {
          ...formData,
          timesheet_teamlead_approve: formData.isRejected ? false : formData.timesheet_teamlead_approve,
          timesheet_is_reject: formData.isRejected,
      });
      
        onUpdate(updatedTimesheet);

        alert('Timesheet updated successfully!');
        // window.location.reload();
      }
    } catch (error) {
      console.error('Error updating timesheet:', error);
    } finally {
      onClosePopup();
    }
  };

  return (
    <div className="box popup" style={{maxWidth:600}}>
      <div className="popup-content">
        <form onSubmit={handleSubmit}>
          <button className='fa fa-window-close' onClick={onClosePopup}></button>

          <div className="form-group">
            <label htmlFor="timesheet_time_start">Time Start</label><br />
            <input
              type="time"
              className="form-control input-box"
              id="timesheet_time_start"
              name="timesheet_time_start"
              value={formData.timesheet_time_start}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="timesheet_time_signin">Time Sign In</label><br />
            <input
              type="time"
              className="form-control input-box"
              id="timesheet_time_signin"
              name="timesheet_time_signin"
              value={formData.timesheet_time_signin}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="timesheet_time_signout">Time Sign Out</label><br />
            <input
              className="form-control input-box"
              type="time"
              id="timesheet_time_signout"
              name="timesheet_time_signout"
              value={formData.timesheet_time_signout}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="timesheet_time_return">Time Return</label><br />
            <input
              className="form-control input-box"
              type="time"
              id="timesheet_time_return"
              name="timesheet_time_return"
              value={formData.timesheet_time_return}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="timesheet_expected_amount">Expected Amount</label>
            <input
              className="form-control input-box"
              type="number"
              id="timesheet_expected_amount"
              name="timesheet_expected_amount"
              value={formData.timesheet_expected_amount}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="timesheet_expected_amount">Comments</label>
            <input
              className="form-control input-box"
              
              id="timesheet_comments"
              name="timesheet_comments"
              value={formData.timesheet_comments}
              onChange={handleChange}
            />
          </div>

          <div>
            <input
              type="checkbox"
              id="timesheet_is_holiday"
              name="timesheet_is_holiday"
              checked={formData.timesheet_is_holiday}
              onChange={handleChange}
            />
            <label htmlFor="timesheet_is_holiday">Is Holiday</label>
          </div>

          <div className="form-group">
            <label htmlFor="timesheet_overtime_amount">Overtime Amount</label>
            <input
              className="form-control input-box"
              type="number"
              id="timesheet_overtime_amount"
              name="timesheet_overtime_amount"
              value={formData.timesheet_overtime_amount}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="timesheet_teamlead_approve">Approval</label>
            <select
              className="form-control input-box"
              id="timesheet_teamlead_approve"
              name="timesheet_teamlead_approve"
              value={formData.isRejected ? 'rejected' : formData.timesheet_teamlead_approve}
              onChange={handleChange}
            >
              <option value={false}>Pending</option>
              <option value={true}>Approved</option>
              {/* <option value={"rejected"}>Reject</option> */}

            </select>
          </div>

          <div className="form-group">
            <button
              type="submit"
              className="btn1"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ApproveTimesheetForm





// import React, { useEffect, useState } from 'react';
// import { updateTimesheet } from '../core/helper/coreapicalls';

// const ApproveTimesheetForm = ({ timesheet, onUpdate, onClosePopup }) => {
//   const [formData, setFormData] = useState({
//     timesheet_time_start: '',
//     timesheet_time_signin: '',
//     timesheet_time_signout: '',
//     timesheet_time_return: '',
//     timesheet_expected_amount: 0,
//     timesheet_is_holiday: false,
//     timesheet_overtime_amount: 0,
//     timesheet_teamlead_approve: false,
//     // Add other Team Lead specific fields if needed
//   });

//   useEffect(() => {
//     setFormData({
//       timesheet_time_start: timesheet.timesheet_time_start || '',
//       timesheet_time_signin: timesheet.timesheet_time_signin || '',
//       timesheet_time_signout: timesheet.timesheet_time_signout || '',
//       timesheet_time_return: timesheet.timesheet_time_return || '',
//       timesheet_expected_amount: timesheet.timesheet_expected_amount || 0,
//       timesheet_is_holiday: timesheet.timesheet_is_holiday || false,
//       timesheet_overtime_amount: timesheet.timesheet_overtime_amount || 0,
//       timesheet_teamlead_approve: timesheet.timesheet_teamlead_approve || false,
//       // Update with other Team Lead specific fields if needed
//     });
//   }, [timesheet]);

//   const handleChange = (e) => {
//     const { name, value, type, checked } = e.target;
//     const updatedValue = type === 'checkbox' ? checked : value;

//     const formattedTime = value ? `${value}:00` : null;

//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: type === 'time' ? formattedTime : updatedValue,
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (
//       !formData.timesheet_time_start ||
//       !formData.timesheet_time_signin ||
//       !formData.timesheet_time_signout ||
//       !formData.timesheet_time_return
//     ) {
//       alert('Please fill in all time fields.');
//       return;
//     }

//     try {
//       const updatedTimesheet = await updateTimesheet(timesheet.timesheet_id, formData);

//       const confirmationMessage = `You entered the following times:\n
//         Time Start: ${formData.timesheet_time_start}\n
//         Time Sign In: ${formData.timesheet_time_signin}\n
//         Time Sign Out: ${formData.timesheet_time_signout}\n
//         Time Return: ${formData.timesheet_time_return}\n
//         Expected Amount: ${formData.timesheet_expected_amount}\n
//         Is Holiday: ${formData.timesheet_is_holiday ? 'Yes' : 'No'}\n
//         Overtime Amount: ${formData.timesheet_overtime_amount}\n
//         Team Lead Approval: ${formData.timesheet_teamlead_approve ? 'Approved' : 'Pending'}`;

//       if (window.confirm(confirmationMessage)) {
//         onUpdate(updatedTimesheet);
//         alert('Timesheet updated successfully!');
//       }
//     } catch (error) {
//       console.error('Error updating timesheet:', error);
//     } finally {
//       onClosePopup();
//     }
//   };

//   return (
//     <div className="box popup">
//       <div className="popup-content">
//         <form onSubmit={handleSubmit}>
//           <button className='fa fa-window-close' onClick={onClosePopup}></button>
//           {/* Form fields */}
//           {/* ... (add your form fields here) */}
//           <div className="form-group">
//             <button type="submit" className="btn1">Submit</button>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default ApproveTimesheetForm;
