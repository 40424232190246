// TeamDropdown.js
import React, { useEffect, useState } from "react";
import { getTeams } from '../core/helper/coreapicalls';

const TeamDropdown = ({ onSelectTeam, defaultValue }) => {
    const [teamData, setTeamData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getTeams();
                setTeamData(data);
            } catch (error) {
                setError(error.message || "An error occurred");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleTeamChange = (event) => {
        const selectedTeamId = event.target.value;
        const team = teamData.find((t) => t.team_id === parseInt(selectedTeamId));
        onSelectTeam(team);
    };

    return (
        <>
            {loading && <p>Loading...</p>}
            {error && <p>Error: {error}</p>}
            {teamData.length > 0 && (
<div>                <select className="form-control input-box" onChange={handleTeamChange} value={defaultValue ? defaultValue.team_id || '' : ''} required>
                    <option value="" disabled>Select a Team</option>
                    {teamData.map((team) => (
                        <option key={team.team_id} value={team.team_id}>
                            {team.team_name}
                        </option>
                    ))}
                </select></div>
            )}
        </>
    );
};

export default TeamDropdown;
