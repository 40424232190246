import React, { useState, useEffect } from 'react';
import ApproveFormManager from './ApproveFormManager';
import { getAllTimesheets } from './helper/coreapicalls';
import Pagination from './Pagination'; // Import the Pagination component



const AllTimesheetOfSingleUser = (props) => {
  const [selectedTimesheet, setSelectedTimesheet] = useState(null);
  const [selecteduserID, setSelecteduserID] = useState(null);
  const [loading, setLoading] = useState(true);
  const [timesheetsData, setTimesheetsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const loadingMessage = () => {
    return (
      loading && (
        <div className="overlay">
          <div className="d-flex justify-content-center align-items-center vh-100">
            <div className="spinner-border text-danger" role="status"></div>
          </div>
        </div>
      )
    );
  };
  useEffect(() => {
    setSelecteduserID(props.userid); // Set the selected user id when props.userid changes

  }, [props.userid]);

  // useEffect(() => {

  // }, [selecteduserID]);

  useEffect(() => {
    // Fetch data only if both currentPage and selecteduserID are not null
    if (currentPage !== null && selecteduserID !== null) {
      fetchData(currentPage, selecteduserID);
    }
  }, [currentPage, selecteduserID]);

  function decimalToTime(decimalHours) {
    // Split the decimal into hours and minutes
    var hours = Math.floor(decimalHours);
    var minutes = Math.round((decimalHours - hours) * 60);

    // Format the time
    var formattedTime = hours + ":" + (minutes < 10 ? '0' : '') + minutes;

    return formattedTime;
  }

  const fetchData = async (page, selecteduserID) => {
    setLoading(true);
    const response = await getAllTimesheets(page, selecteduserID);
    setTimesheetsData(response.timesheetsData);
    setTotalPages(Math.ceil(response.count / 100)); // Assuming 10 items per page
    setLoading(false);
  };
  // const handleEditClick = (timesheet) => {
  //   setSelectedTimesheet(timesheet);
  // };

  const handleSaveEdit = (editedTimesheet) => {
    setTimesheetsData((prevData) =>
      prevData.map((timesheet) =>
        timesheet.timesheet_id === editedTimesheet.timesheet_id ? editedTimesheet : timesheet
      )
    );
    setSelectedTimesheet(null);
  };

  const handleCloseEdit = () => {
    setSelectedTimesheet(null);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  return (

    <div className="row mt-2">
      {loadingMessage()}
      <div className="table-container" style={{ maxHeight: '500px', overflowY: 'auto' }}>
        <table className="table table-striped">
          <thead style={{ position: 'sticky', top: 0, zIndex: 1, background: 'yellow ' }}>
            <tr style={{maxHeight:30, fontSize :13, alignContent:'center',  textAlign:'center'}}  >
              
              {/* <th style={{maxHeight:30, fontSize :13, alignContent:'center',  textAlign:'center'}}>Edit</th> */}
              <th >Date<br /> YYYY-MM-DD</th>
              <th >Name</th>
              <th >Start <br /> Time</th>
              <th >Sign <br /> In</th>
              <th >Sign <br /> Out</th>
              <th >Return</th>
              <th >Normal<br /> Hrs</th>
              <th >Total<br /> Hrs</th>
              <th >OT<br /> Hrs</th>
              <th >Exp. <br />OT</th>
              <th >OT<br /> Amount</th>
              <th >Allowance</th>
              <th >Total</th>
              <th >Holiday</th>
              <th >Anch</th>
              <th >Overseas</th>
              <th >DDK</th>
              <th >Team <br /> Lead</th>
              <th >Dept Mgr</th>
              <th >Final <br />Status</th>
              <th >Process <br />Status</th>
              <th >Comments</th>
            </tr>
          </thead>
          <tbody>
            {timesheetsData.map((timesheet) => (
              <tr key={timesheet.timesheet_id}>
                <td style={{ alignContent: 'center', textAlign: 'center' }} >{timesheet.timesheet_date}</td>
                <td style={{ alignContent: 'center', textAlign: 'center' }}>{timesheet.userName}</td>
                <td style={{ alignContent: 'center', textAlign: 'center' }}>{timesheet.timesheet_time_start}</td>
                <td style={{ alignContent: 'center', textAlign: 'center' }}>{timesheet.timesheet_time_signin}</td>
                <td style={{ alignContent: 'center', textAlign: 'center' }}>{timesheet.timesheet_time_signout}</td>
                <td style={{ alignContent: 'center', textAlign: 'center' }}>{timesheet.timesheet_time_return}</td>
                <td style={{ alignContent: 'center', textAlign: 'center' }}>{decimalToTime(timesheet.timesheet_normal_hours)}</td>
                <td style={{ alignContent: 'center', textAlign: 'center' }}>{decimalToTime(timesheet.timesheet_total_hours)}</td>
                <td style={{ alignContent: 'center', textAlign: 'center' }}>{decimalToTime(timesheet.timesheet_over_time_hours)}</td>
                <td style={{ alignContent: 'center', textAlign: 'center' }}>{timesheet.timesheet_expected_amount}</td>
                <td style={{ alignContent: 'center', textAlign: 'center' }}>{timesheet.timesheet_overtime_amount}</td>
                <td style={{ alignContent: 'center', textAlign: 'center' }}>{timesheet.timesheet_allowance_amount}</td>
                <td style={{ alignContent: 'center', textAlign: 'center' }}>{timesheet.timesheet_Total_amount}</td>
                <td style={{ alignContent: 'center', textAlign: 'center' }}>{timesheet.timesheet_is_holiday ? (
                  <span className="fa fa-check badge text-white bg-success m-2" style={{ alignContent: 'center', textAlign: 'center' }} aria-hidden="true"  >
                    &nbsp;
                  </span>
                ) : (
                  <span className="fa fa-times badge text-white bg-secondary m-2" style={{ alignContent: 'center', textAlign: 'center' }} aria-hidden="true"  >
                    &nbsp;
                  </span>
                )}</td>
                {/* Anchorage check */}
                <td style={{ alignContent: 'center', textAlign: 'center' }}>{timesheet.timesheet_attendance_id.attendance_is_anchorage ? (
                  <span className="fa fa-check badge text-white bg-success m-2" style={{ alignContent: 'center', textAlign: 'center' }} aria-hidden="true"  >
                    &nbsp;
                  </span>
                ) : (
                  <span className="fa fa-times badge text-white bg-secondary m-2" style={{ alignContent: 'center', textAlign: 'center' }} aria-hidden="true"  >
                    &nbsp;
                  </span>
                )}
                </td>
                {/* Overseas Check */}
                <td style={{ alignContent: 'center', textAlign: 'center' }}>{timesheet.timesheet_attendance_id.attendance_is_overseas ? (
                  <span className="fa fa-check badge text-white bg-success m-2" style={{ alignContent: 'center', textAlign: 'center' }} aria-hidden="true"  >
                    &nbsp;
                  </span>
                ) : (
                  <span className="fa fa-times badge text-white bg-secondary m-2" style={{ alignContent: 'center', textAlign: 'center' }} aria-hidden="true"  >
                    &nbsp;
                  </span>
                )}
                </td>
                {/* Drydock Check */}
                <td style={{ alignContent: 'center', textAlign: 'center' }}>{timesheet.timesheet_attendance_id.attendance_is_dubaidrydock ? (
                  <span className="fa fa-check badge text-white bg-success m-2" style={{ alignContent: 'center', textAlign: 'center' }} aria-hidden="true"  >
                    &nbsp;
                  </span>
                ) : (
                  <span className="fa fa-times badge text-white bg-secondary m-2" style={{ alignContent: 'center', textAlign: 'center' }} aria-hidden="true"  >
                    &nbsp;
                  </span>
                )}
                </td>
                <td style={{ alignContent: 'center', textAlign: 'center' }}>
                  {/* teamlead approve */}
                  {timesheet.timesheet_teamlead_approve ? (
                    <span className="fa-solid fa-thumbs-up badge bg-success m-2 " >
                      &nbsp;
                    </span>
                  ) : (
                    <span className="badge bg-warning text-dark m-2 fa-solid fa-thumbs-down" >
                      &nbsp;
                    </span>
                  )}
                </td>
                <td style={{ alignContent: 'center', textAlign: 'center' }}>
                  {/* teamlead approve */}
                  {timesheet.timesheet_dept_manager_approve ? (
                    <span className="fa-solid fa-thumbs-up badge bg-success m-2 " >
                      &nbsp;
                    </span>
                  ) : (
                    <span className="badge bg-warning text-dark m-2 fa-solid fa-thumbs-down" >
                      &nbsp;
                    </span>
                  )}
                </td>
                <td>
                  {!timesheet.timesheet_dept_manager_approve ? (
                    <button type="button" className="btn btn-outline-danger p-1 m-1" style={{ maxHeight: 30, fontSize: 13 }} >
                      Waiting
                    </button>
                  ) : (
                    <button type="button" className="btn btn-outline-success p-1 m-1" style={{ maxHeight: 30, fontSize: 13 }}>
                      Accepted
                    </button>
                  )}
                </td>
                <td>
                  {!timesheet.timesheet_final_process_status ? (
                    <button type="button" className="btn btn-outline-danger p-1 m-1" style={{ maxHeight: 30, fontSize: 13 }} >
                      Waiting
                    </button>
                  ) : (
                    <button type="button" className="btn btn-outline-success p-1 m-1" style={{ maxHeight: 30, fontSize: 13 }}>
                      Completed
                    </button>
                  )}
                </td>
                <td>{timesheet.timesheet_comments}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onNextPage={handleNextPage}
        onPrevPage={handlePrevPage}
      />
      {selectedTimesheet && (
        <ApproveFormManager
          timesheet={selectedTimesheet}
          onUpdate={handleSaveEdit}
          onClosePopup={handleCloseEdit}
        />
      )}
    </div>
  );
};
export default AllTimesheetOfSingleUser;



