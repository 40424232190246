

import React, { useEffect, useState } from 'react';
import { isAuthenticated } from '../auth/helper';
import Menu from '../core/Menu';
import WhatsAppButton from '../core/WhatsAppButton';
import Footer from '../core/Footer';
import AdminApprovalControl from '../core/AdminApprovalControl';


const AdminDashboard = () => {
    const [userData, setUserData] = useState(null);
    // const [allTimesheetsList, setallTimesheetsList] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            const user = await isAuthenticated();
            setUserData(user);
        };
        fetchData();
    }, []); // Empty dependency array ensures useEffect runs once on mount

    return (
        <div className="dashboard-wrapper ">
            <div className="container-grow">
                <Menu></Menu>
                <WhatsAppButton></WhatsAppButton>
                {userData && (
                    <div className="m-3 ">
                        <div className="d-flex">
                            <div className='pr-5'>Welcome, &nbsp;</div>
                            <div className="pl-5">{userData.emp_name}</div>
                        </div>
                        <div className='pb-2 d-flex '>
                            <div className='mt-2'>
                                <button type="button" className="btn btn-primary m-2" style={{ height: '40px' }}>
                                    Time Sheets
                                </button>
                            </div>
                            {/* <div >    {searchUser && <SearchUser onSearch={handleSearch} />}</div> */}
                        </div>
                        <div>
                            <div>
                                <AdminApprovalControl />
                            </div>
                            {/* {allTimesheetsList && (

                            )} */}
                        </div>
                    </div>
                )}
            </div>
            <Footer></Footer>
        </div>
    );
};
export default AdminDashboard;
